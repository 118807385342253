.dashboradnavContainer {
  width: 100%;
  background-color: #29347a;
  height: 60px;
  color: #fff;
  padding: 0px 120px;
  position: fixed;
  top: 45px;
  left: 0;
  z-index: 999;
}

.dashboradnavContainer section:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 60px;
  max-width: 1500px;
  margin: auto;
}

.hide,
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.dashboradnavContainer section:first-child div div {
  justify-content: space-evenly;
  width: 400px;
  align-items: center;
  flex-direction: row;
}

.dashboradnavContainer section:first-child div div a {
  text-decoration: none;
}

.dashboradnavContainer section:first-child div div p {
  margin: 0;
  font-family: Nunito, sans-serif;
  font-weight: 700;
  color: #c4cefe;
  cursor: pointer;
  transition: 0.5s;
  position: relative;
  padding: 20px 0;
}

.dashboradnavContainer section:first-child div div p:hover {
  color: #fff;
}

.dashboradnavContainer section:first-child div div .active {
  color: #fff;
}

.dashboradnavContainer section:first-child div div .active:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: #7f8dff;
}

.dashboradnavContainer section:first-child span {
  cursor: pointer;
  padding: 5px;
  margin-right: 10px;
  color: #fff;
  border-radius: 50%;
  transition: 0.5s;
  display: none;
}

.dashboradnavContainer section:first-child span:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.dashboradnavContainer section:first-child div img {
  width: auto;
  height: 40px;
  cursor: pointer;
}

.dashboradnavContainer section:first-child div:nth-child(2) p {
  font-size: 1rem;
  font-weight: 900;
  color: #fff;
  cursor: pointer;
  transition: 0.5s;
  width: 40px;
  height: 40px;
  background-color: #798bff;
  margin: 0;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.dashboradnavContainer section:first-child div:nth-child(2) {
  height: 60px;
  display: flex;
  align-items: center;
}

.user-info {
  margin-left: 1rem;
  height: 60px;
  margin-top: -25px;
  cursor: pointer;
}

.user-status-verified {
  color: #1ee0ac;
}

.user-status {
  font-size: 11px;
  font-weight: 500;
}

.user-name {
  color: #c4cefe;
  font-size: 12px;
  line-height: 1px;
  font-weight: 700;
  margin-top: -20px;
  font-family: Nunito, sans-serif;
}

[class*="dropdown-indicator"]:after {
  border: none !important;
  font-family: "Nioicon";
  content: "^";
  margin-left: 0.25rem;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  transform: rotate(180deg);
}

.nk-quick-nav .dropdown-menu {
  margin-top: 11px;
  margin-right: -6px;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  text-align: left;
  min-width: 180px;
  padding: 0 0;
  margin: 0.125rem 0 0;
  font-size: 0.8125rem;
  color: #526484;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5e9f2;
  border-radius: 4px;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.icon-status {
  position: relative;
  display: inline-flex;
  margin: 0px 20px;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  transition: 0.5s;
}

.icon-status-2 {
  position: relative;
  display: inline-flex;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  transition: 0.5s;
}

.icon-status:hover,
.icon-status-2:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.icon-status:after {
  position: absolute;
  border-radius: 50%;
  right: 10px;
  top: 10px;
  height: 10px;
  width: 10px;
  border: 2px solid #fff;
  content: "";
  background: #09c2de;
}

.user {
  display: flex;
  height: 10vh;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  .dashboradnavContainer {
    padding: 0px 20px;
  }
}

@media only screen and (max-width: 900px) {
  .dashboradnavContainer section:first-child span {
    display: block;
  }
  .hide {
    display: none;
  }
}

@media only screen and (max-width: 550px) {
  .hideIcon {
    display: none;
  }
  .flex-2 {
    flex-direction: row-reverse;
  }
}
