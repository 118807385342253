.mobile {
  display: none;
}

.services-container {
  padding: 100px 10%;
  text-align: center;
  color: #fff;
}

.service {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 100px;
}

.service div {
  margin: 50px;
  text-align: justify;
}

.service div h2 {
  /* color: var(--secondary); */
  color: #fff;
}

.tool-container {
  padding: 0px 10%;
  position: relative;
  /* background: linear-gradient(var(--primary) 0%, var(--secondary) 50%); */
  background-color: rgba(256, 256, 256, 0.7);
  color: rgb(17, 17, 37);
}

.tool-container .tool-first {
  padding: 150px 0px;
  /* color: #fff; */
  display: grid;
  grid-gap: 50px;
  grid-template-columns: repeat(2, 1fr);
}

.tool-container .tool-first p {
  text-align: justify;
}

.tool-container .tool-first img {
  width: 100%;
}

.tool-container .tool {
  position: absolute;
  bottom: -70px;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(2, 1fr);
  border-radius: 10px;
  padding: 0px 10%;
  width: 100%;
}

.tool-container .tool .tool-cont {
  background-color: #fff;
  display: flex;
  padding: 30px 30px 10px 10px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  /* width: 100%; */
}

.tool-container .tool .tool-cont img {
  width: 60px;
  height: 50px;
}

.tool-container .tool .tool-cont div {
  margin-left: 20px;
}

.tool-container .tool .tool-cont div p {
  text-align: justify;
}

.more-container {
  padding: 150px 10% 100px;
  text-align: center;
  color: #fff;
}

.more-container-grid {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(3, 1fr);
}

.more-container-grid div {
  padding: 50px 50px 30px;
  border-radius: 20px;
  border: 1px solid rgb(231, 228, 228);
  transition: 0.5s ease-in-out;
}

.more-container-grid div:hover {
  color: #fff;
  background-color: rgba(256, 256, 256, 0.3);
}

.more-container-grid div:hover h1 {
  color: black;
}

.more-container-grid div p {
  line-height: 50px;
}

.more-container-grid div a {
  display: block;
  color: #fff;
  padding: 15px 0px;
  width: 200px;
  margin: auto;
  border-radius: 50px;
  background: linear-gradient(var(--primary) 0%, var(--secondary) 50%);
}

.more-container-grid div h1 {
  margin-top: 20px;
  font-size: 3rem;
  color: var(--secondary);
}

.more-sub-container {
  padding: 50px 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  /* margin-bottom: 50px; */
  /* background: linear-gradient(var(--primary) 0%, var(--secondary) 50%); */
  background-color: rgba(0, 0, 0, 0.2);
}

.more-sub-container a {
  color: #fff;
  border: 1px solid var(--tertiary);
  padding: 10px 25px;
  margin-right: 25px;
  border-radius: 50px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.more-sub-container a:hover {
  color: #fff;
  background-color: rgb(29, 28, 28);
  transition: 0.5s ease-in-out;
}

@media screen and (max-width: 1400px) {
  .services-container {
    padding: 100px 5%;
    text-align: center;
  }
  .service {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
  }

  .service div {
    margin: 30px;
  }
  .tool-container {
    padding: 0px 5%;
  }
  .tool-container .tool {
    padding: 0px 5%;
  }
  .more-sub-container {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media screen and (max-width: 1200px) {
  .services-container {
    padding: 100px 1%;
    text-align: center;
  }
  .tool-container .tool {
    padding: 0px 1%;
    bottom: -265px;
    display: block;
  }
  .tool-container .tool .tool-cont {
    padding: 30px 30px 5px 5px;
    margin-top: 10px;
  }

  .tool-container .tool-first {
    display: block;
  }

  .tool-container .tool-first img {
    margin-left: 0px;
  }

  .more-sub-container {
    padding-left: 1%;
    padding-right: 1%;
  }
}

@media screen and (max-width: 1200px) {
  .more-container {
    padding: 300px 10% 300px;
    text-align: center;
  }
  .more-container-grid {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(2, 1fr);
  }

  .more-container {
    padding: 300px 10% 30px;
    text-align: center;
  }
}

@media screen and (max-width: 800px) {
  .more-container-grid {
    display: block;
  }

  .more-container-grid div p {
    line-height: 40px;
  }

  .more-container-grid div {
    margin-top: 30px;
  }

  .service {
    display: block;
  }

  .service h2 {
    text-align: center;
  }

  .service div {
    margin: 0px;
  }

  .more-sub-container {
    display: block;
    text-align: center;
  }

  .more-sub-container a {
    margin-right: 0px;
  }

  .mobile,
  .desktop-m {
    display: block;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .desktop {
    display: none;
    text-align: center;
  }
}

@media screen and (max-width: 560px) {
  .tool-container {
    margin-bottom: 200px;
  }

  .tool-container .tool {
    bottom: -460px;
  }

  .tool-container .tool .tool-cont {
    background-color: #fff;
    display: block;
    text-align: center;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 20px 10px 0px;
  }

  .tool-container .tool .tool-cont div {
    margin: 0px;
    margin-top: 20px;
    padding-bottom: 1px;
  }

  .tool-container .tool .tool-cont div p {
    text-align: justify;
  }

  .service img {
    width: 300px;
  }
}

@media screen and (max-width: 400px) {
  .more-container-grid div {
    padding: 50px 20px 20px;
  }

  .more-container-grid div a {
    display: block;
    color: #fff;
    padding: 10px 0px;
    width: 150px;
    margin: auto;
    border-radius: 50px;
    background: linear-gradient(var(--primary) 0%, var(--secondary) 50%);
  }
}

@media screen and (max-width: 360px) {
  .service img {
    width: 250px;
  }
}

.servSlides {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../assets/cryp3.jpg");
  width: 100%;
  min-height: 75vh;
  background-size: cover;
  background-attachment: fixed;
  background-position: center right;
  background-repeat: no-repeat;
  animation-name: fade;
  animation-duration: 1s;
}
