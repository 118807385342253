.academyContainer {
  background-color: #f5f6fa;
  width: 100%;
  padding: 50px 120px;
}

.academyContentContainer {
  background-color: #f5f6fa;
  width: 100%;
  padding: 0px 120px;
}

.plansCon {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: #fff;
  border-radius: 4px;
}

.titleDivCon {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 60px;
}

.titleDiv {
  padding: 20px;
  width: 25%;
  max-width: 50%;
  height: 150px;
  display: grid;
  place-items: center;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  position: relative;
}

.iconabs {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70px;
  width: 70px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: none;
  opacity: 0.2;
  transition: all 0.3s ease-in-out;
}

.iconabsactive {
  box-shadow: 0px 0px 20px -10px #181e47;
  opacity: 1;
}

.iconabsicon {
  font-size: 40px;
}

.titleDivActive {
  background-color: #29347a;
  color: #fff;
}

.academyContentHeader {
  width: 100%;
  margin-bottom: 10px;
  background-image: url("../assets/academy-content.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 30vh;
  position: relative;
  margin-top: 60px;
}

.academyContentHeaderAbs {
  padding: 20px 120px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 30vh;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
}

.academyContentHeaderAbs > h4 {
  color: #fff;
}

.academyContentHeaderAbs > p {
  color: grey;
}

.goBack {
  position: fixed;
  right: 20px;
  top: 50%;
  opacity: 0.4;
  color: black;
  width: 70px;
  height: 70px;
  background-color: #29347a28;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px 0px;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  z-index: 1000000;
}

.goBack > p {
  margin: 0;
  font-size: 10px;
}

.goBack:hover {
  opacity: 1;
  background-color: #29347a80;
}

.video-container {
  width: 100%;
  height: 50vh;
  margin-top: 60px;
  position: relative;
}

#colorChange {
  background-color: #29347a11;
}

#colorChange {
  padding: 16px;
}

.abs-video {
  width: 100%;
  height: inherit;
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0vh 120px;
  font-size: 40px;
  font-weight: 900;
  text-transform: uppercase;
}

.video {
  width: 100%;
  height: inherit;
  border: none;
  outline: none;
  object-fit: cover;
}

.psedoDiv {
  position: relative;
  color: #29347a;
  transition: 0.5s;
  cursor: pointer;
  z-index: 2;
  text-align: center;
}

.psedoDiv p,
.psedoDivActive p,
.psedoDivDisabled p {
  margin-bottom: 0;
}

.psedoDivActive {
  position: relative;
  color: white;
  transition: 0.5s;
  cursor: pointer;
  z-index: 2;
  background-color: #29347a;
  text-align: center;
}

.psedoDivDisabled {
  position: relative;
  color: white;
  transition: 0.5s;
  cursor: pointer;
  z-index: 2;
  background-color: #29347a48;
  cursor: no-drop;
  text-align: center;
}

.psedoDiv:hover {
  color: white;
}

.psedoDiv:hover::after {
  background-color: #29347a;
  bottom: 0;
  color: #fff;
}

.psedoDiv::after {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 50%;
  right: 0%;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 10px;
}

@media only screen and (max-width: 1200px) {
  .academyContainer {
    padding: 5vh 20px 50px;
  }
  .academyContentContainer {
    padding: 0vh 20px 20px;
  }
  .abs-video {
    padding: 0vh 20px;
    font-size: 30px;
  }
}

@media only screen and (max-width: 700px) {
  .abs-video {
    font-size: 25px;
    text-align: center;
    justify-content: center;
  }
  .academyContentHeaderAbs {
    padding: 20px;
  }
  .titleDiv {
    width: 50%;
    height: 100px;
  }
  .titleDiv h3 {
    font-size: 15px;
  }
  .titleDivCon {
    justify-content: space-between;
  }
  .psedoDiv {
    text-align: left;
    padding-left: 20px;
  }

  .psedoDivActive {
    text-align: left;
    padding-left: 20px;
  }

  .psedoDivDisabled {
    text-align: left;
    padding-left: 20px;
  }

  .iconabs {
    height: 40px;
    width: 40px;
  }

  .iconabsicon {
    font-size: 20px;
  }
}
