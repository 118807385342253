footer {
  /* background: linear-gradient(var(--primary) 0%, var(--secondary) 50%); */
  background-color: rgb(17, 17, 37);
  color: #fff;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 100px 10% 20px;
}

footer li {
  padding: 0px 10px 10px;
}

footer a {
  color: #fff;
  font-size: 20px;
}

footer a:hover {
  color: #fff;
}

.mail-icon {
  font-size: 40px;
  background-color: black;
  border-radius: 50%;
  padding: 5px;
  margin-right: 20px;
}

@media screen and (max-width: 960px) {
  footer {
    display: block;
    padding: 80px 5% 10px;
  }

  footer ul,
  footer li {
    margin-left: 0px !important;
    padding-left: 0px;
  }

  footer li {
    padding-bottom: 30px;
  }

  footer li:last-child {
    padding-bottom: 0px;
  }

  footer div,
  footer ul {
    margin-bottom: 50px;
  }
}
