.general-container {
  padding: 10px 10%;
  font-size: 20px;
  text-align: justify;
  background-color: #fff;
}

.term-container {
  background-color: #fff;
}
.terms-container {
  padding-top: 20vh;
}

.terms-container .list li {
  list-style-type: disc;
  font-size: 16px;
  font-family: "Open Sans";
}
.terms-container p {
  font-size: 16px;
  font-family: "Open Sans";
}

.faq-container {
  text-align: center;
}

.faq-container div {
  padding: 40px 20px 20px;
  margin: 20px 0px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  text-align: justify;
  font-size: 80%;
}

.faq-container div span {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}

.faq-container div span .arrow {
  font-size: 30px;
  color: var(--secondary);
}

.form-container {
  display: flex;
  justify-content: center;
  margin-top: 10vh;
}

.form-container .form-card {
  box-shadow: 4px 2px 20px 0px rgba(0, 0, 0, 0.3);
  padding: 30px;
  width: 35%;
  text-align: center;
  border-radius: 10px;
  background-color: white;
}

.form-container .form-card form {
  box-shadow: 4px 2px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 30px;
}

.form-container .form-card form div {
  margin: 10px;
  border-bottom: 1px solid rgba(223, 223, 223, 0.5);
}

.form-container .form-card form div input,
.form-container .form-card form div textarea {
  width: 100%;
  border: none;
  outline: none;
  padding: 10px;
}

.form-container .form-card form .btn {
  background-color: var(--secondary);
  color: #fff;
  font-size: 20px;
  border-radius: 50px;
  padding: 10px 20px;
  margin-top: 15px;
  transition: 0.5s ease-in-out;
}

.form-container .form-card form .btn:hover {
  background-color: black;
  color: #fff;
}

@media screen and (max-width: 1200px) {
  .general-container {
    padding: 10px 2%;
  }
  .faq-container {
    padding: 0px 5%;
  }
  .faq-container div {
    margin: 20px 10px;
  }
  .faq-container div span {
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .faq-container div span .right {
    display: flex;
    justify-content: right;
  }
  .form-container .form-card {
    width: 50%;
  }
}

@media screen and (max-width: 800px) {
  .general-container {
    padding: 10px 2%;
  }
  .form-container .form-card {
    width: 70%;
  }
  .terms-container {
    padding: 20vh 30px;
  }
}

@media screen and (max-width: 500px) {
  .general-container {
    padding: 10px 2%;
  }
  .form-container .form-card {
    width: 90%;
  }
  .form-container .form-card form {
    padding: 20px 10px 10px;
  }
  .form-container .form-card form div {
    margin: 5px;
    border-bottom: 1px solid rgba(223, 223, 223, 0.5);
  }
  .form-container .form-card form .btn {
    font-size: 16px;
    padding: 8px 18px;
    margin-top: 10px;
    margin-bottom: 10px;
    transition: 0.5s ease-in-out;
  }
  .terms-container {
    padding: 20vh 30px;
  }
}
