nav {
  position: fixed;
  width: 100%;
  height: 15vh;
  padding: 15px 10%;
  box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.7);
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  top: 45px;
}

nav .logo img {
  width: 80px;
}

nav .menu {
  display: none;
  cursor: pointer;
}

nav .menu .menu-icon {
  font-size: 30px;
}

nav .links,
nav .links-active {
  display: flex;
  align-items: center;
}

nav .links li,
nav .links-active li {
  margin: 10px 20px -10px;
}

nav .links li a,
nav .links-active li a {
  font-size: 20px;
  color: black;
}

nav .links li a:hover,
nav .links-active li a:hover {
  font-size: 20px;
  color: var(--secondary);
}

nav .links li:last-of-type,
nav .links-active li:last-of-type {
  background-color: var(--secondary);
  padding: 8px 25px;
  border-radius: 10px;
  transition: 0.5s ease-in-out;
}

nav .links li:last-of-type:hover,
nav .links-active li:last-of-type:hover {
  background-color: black;
}

nav .links li:last-of-type a,
nav .links-active li:last-of-type a {
  color: #fff;
}

@media screen and (max-width: 960px) {
  nav .menu {
    display: block;
  }

  nav .links {
    position: absolute;
    left: 0px;
    top: -55vh;
    padding: 0px 0px 25px;
    display: block;
    width: 150%;
    background-color: white;
    transition: 0.3s;
    z-index: -1;
  }

  nav .links-active {
    position: absolute;
    left: 0px;
    top: 15vh;
    padding: 0px 0px 25px;
    display: block;
    width: 150%;
    background-color: white;
    transition: 0.3s;
    z-index: -1;
  }

  nav .links li,
  nav .links-active li {
    padding: 5px;
    margin-left: 0px;
  }

  nav .links li:hover,
  nav .links-active li:hover {
    background-color: rgba(91, 91, 230, 0.3);
  }

  nav .links li:last-of-type,
  nav .links-active li:last-of-type {
    padding: 8px 25px;
    margin-top: 20px;
    margin-left: 1%;
    width: 105px;
  }
}

@media screen and (max-width: 1400px) {
  nav {
    padding: 15px 5%;
  }
}

@media screen and (max-width: 1200px) {
  nav {
    padding: 15px 1%;
  }
}

@media screen and (max-width: 600px) {
  nav .logo img {
    width: 60px;
  }

  nav {
    position: fixed;
    width: 100%;
    height: 13vh;
    padding: 15px 20px;
    box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
  }

  nav .links-active {
    position: absolute;
    left: 0px;
    top: 13vh;
    padding: 0px 0% 20px;
    width: 150%;
    background-color: #fff;
    transition: 0.3s;
    z-index: -1;
  }
}
