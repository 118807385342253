.adminHomeContainer {
  width: 100%;
  min-height: 100vh;
  background: #f5f6fa;
  padding: 100px 20px;
}

.adminh3 {
  font-size: 1.75rem;
  font-weight: 700;
  color: #364a63;
}

.adminp1 {
  color: #8094ae !important;
}

.divflex1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.divflex1 > article {
  border: 1px solid #dbdfea;
  padding: 1.25rem;
  width: calc(50% - 1rem);
  background-color: #fff;
  border-radius: 4px;
}

.divflex1 > article:nth-child(2) {
  margin: 0px 30px;
}

.divflex1 > article > h6 {
  color: #8094ae;
}

.divflex1 > article > p {
  font-size: 1.5rem;
  color: #364a63;
}

.divflex2 {
  border: 1px solid #dbdfea;
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-color: #fff;
  width: 100%;
}

.divflex2 > article {
  padding: 1.25rem;
}

.divflex2 > article:first-child {
  padding-bottom: 0px;
  border-bottom: 1px solid #dbdfea;
}

.divflex2 > article > h6 {
  font-size: 1.05rem;
  font-weight: 700;
  color: #364a63;
}

.divflex2 > article > p {
  font-size: 12px;
  color: #8094ae;
}

.divflex2 > article > span {
  color: #6576ff;
  border-bottom: 3px solid #6576ff;
  padding-bottom: 0.75rem;
  display: block;
  float: left;
  clear: left;
  font-weight: 700;
}

.linkspan {
  color: #798bff;
}

.divflex2 > article:last-child > p:first-child {
  color: #8094ae;
  font-size: 13px;
  font-weight: 700;
}

.divflex2 > article > div > p:first-child,
.divflex2 > article > div > p:nth-child(3) {
  font-size: 1.25rem;
  line-height: 1.1;
  color: #364a63;
  margin-bottom: 0px;
}

.divflex2 > article > div > p:nth-child(2),
.divflex2 > article > div > p:nth-child(4) {
  font-size: 11px;
  color: #8094ae;
  text-transform: uppercase;
  margin-top: 0.25rem;
  letter-spacing: 0.1em;
}

.table1 {
  display: block;
}

.table2 {
  display: none;
}

@media (max-width: 750px) {
  .divflex1 > article {
    width: 100%;
  }
  .divflex1 > article:nth-child(2) {
    margin: 0px;
  }
  .divflex1 > article {
    margin: 20px 0px;
  }
  .divflex1 {
    display: block;
  }
}

@media (min-width: 576px) {
  .adminHomeContainer {
    padding: 100px 40px;
  }
  .divflex1 > article,
  .divflex2 > article {
    padding: 1.5rem;
  }
  .divflex2 {
    width: 50%;
  }
  .table2 {
    display: block;
  }

  .table1 {
    display: none;
  }
}
