.plan-container {
  text-align: center;
  background-color: #f5f6fa;
  padding: 15vh 120px 50px;
}

.cards, #cardsnotres {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 30px;
}

#cardsres {
  display: none;
}

.cards .card {
  background-color: white;
  padding-top: 30px;
  border-radius: 5px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
}

.cards .card .top {
  display: flex;
  justify-content: space-between;
  padding: 25px 80px;
  border-bottom: 1px solid rgba(209, 206, 206, 0.5);
}

.card p {
  font-size: 15px;
}

.card a {
  background-color: #f5f6fa;
  color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  padding: 3px 10px;
  font-size: 15px;
  display: block;
  margin: 20px 50px 0px;
}

.card a:hover {
  color: rgba(0, 0, 0, 0.8);
}

.plan-container ul {
  padding-left: 50px !important;
  padding: 30px 50px;
}

.plan-container ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.i {
  width: 55%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.i p {
  padding: 0px;
  margin: 0px;
}

.plan-container .button {
  background-color: rgb(75, 135, 214);
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 8px 10px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 70px auto;
  width: 170px;
}

.plan-container .cards .card button {
  background-color: #f5f6fa;
  color: rgba(128, 128, 128);
  border: 1px solid rgba(128, 128, 128, 0.289);
  border-radius: 5px;
  padding: 8px 10px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  width: 170px;
}

.button:hover {
  color: #fff;
}

@media screen and (max-width: 1200px) {

  .card {
    margin-top: 20px;
    background-color: green;
  }
  .plan-container {
    padding: 15vh 20px 50px;
  }
}

@media screen and (max-width: 900px) {
  #cardsres {
    display: block;
    width: 100%;
  }
  #cardsnotres {
    display: none;
  }
}

@media screen and (max-width: 470px) {
  .card a {
    margin: 20px 10px 0px;
  }
  #cardsres {
    display: block;
    width: 100%;
  }
  #cardsnotres {
    display: none;
  }
}

@media screen and (max-width: 370px) {
  .top p {
    font-size: 16px;
  }

  #cardsres {
    display: block;
    width: 100%;
  }
  #cardsnotres {
    display: none;
  }

  .top .x {
    margin: 0px;
  }

  .cards .card .top {
    padding: 25px 20px;
  }

  .plan-container {
    padding: 30px 2%;
  }

  .cards {
    padding-top: 10px;
  }

  .card a {
    margin: 20px 0px 0px;
  }

  .plan-container ul {
    padding-left: 20px !important;
    padding: 30px 20px;
  }

  .button {
    margin: 30px auto;
  }
}
