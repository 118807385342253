.about-container {
  padding: 10px 0px;
  width: 100%;
  background-color: var(--secondary);
  position: relative;
}

.no-fade {
  opacity: 0;
  margin-top: 50px;
}

.div-fade {
  opacity: 1;
  transition: all 1.5s;
  margin-top: 0px;
}

/* .about-container .image {
    display: block;
    margin-top: 100px;
    margin-left: -230px;
    margin-right: 10px;
} */

.about-about,
.about-text {
  /* margin-bottom: 50px; */
  text-align: center;
  padding: 10px 10%;
  color: #fff;
}

.about-text {
  padding: 10px 0px;
}

.about-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}

.about-info div {
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); */
  padding: 50px 10px 20px;
  text-align: center;
  position: relative;
  /* background-color: #fff; */
  color: #fff;
}

.about-info div h5 {
  margin-top: 20px;
}

.about-info div img {
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  display: block;
  width: 80px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  padding: 10px 5px;
}

.philosophy-container {
  /* margin-top: 50px; */
  padding: 50px 10% 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
}

.philosophy-container .philosophy p {
  text-align: justify;
}

.philosophy-container .start {
  text-align: center;
  padding: 50px 20px;
  margin: 0px 0px 30px 50px;
  color: #fff;
  background: linear-gradient(var(--primary) 0%, var(--secondary) 50%);
}

.philosophy-container .start p {
  margin: 20px 0px 30px;
}

.philosophy-container .start a {
  color: #fff;
  border: 1px solid var(--tertiary);
  padding: 10px 25px;
  border-radius: 50px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.philosophy-container .start a:hover {
  color: #fff;
  background-color: rgb(29, 28, 28);
  transition: 0.5s ease-in-out;
}

.philosophy-container img {
  position: absolute;
  top: -50px;
  right: -50vh;
  height: 100px;
  display: block;
  width: 900px;
  height: 500px;
  z-index: -1;
}

.how-container {
  padding: 50px 10%;
  /* color: #fff; */
  text-align: center;
  /* background: linear-gradient(var(--primary) 0%, var(--secondary) 50%); */
  background-color: #fff;
  position: relative;
  color: rgb(17, 17, 37);
}

.how-container .how-info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 60px 30px 20px;
}

.how-container .how-info img {
  width: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1400px) {
  .about-container {
    padding: 20px 5% 10px 0px;
  }

  .philosophy-container .start {
    margin: 0px 0px 30px 50px;
  }

  .philosophy-container {
    padding: 50px 5%;
  }
}

@media screen and (max-width: 1200px) {
  .about-container {
    display: block;
    padding: 30px 1% 0px;
  }

  .about-container .image {
    width: 100%;
    margin-left: 0px;
  }

  .philosophy-container {
    display: block;
    padding: 50px 10%;
    margin-top: 0px;
  }

  .philosophy-container .philosophy {
    margin-top: 0px;
  }

  .philosophy-container .start {
    margin: 0px;
  }

  .philosophy-container img {
    display: none;
  }
}

@media screen and (max-width: 850px) {
  .about-about {
    margin-top: 20px;
  }

  .about-info {
    display: block;
  }

  .how-container .how-info {
    display: block;
  }
}
