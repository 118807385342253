* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito";
  scroll-behavior: smooth;
}

.nunito {
  font-family: "Nunito";
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#google_translate_element {
  width: 100%;
  background-color: #29347a;
  /* height: 60px; */
  color: #fff;
  padding: 0px 120px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  color: #fff;
}
.goog-te-combo {
  font-family: "Nunito";
}
.goog-te-gadget {
  color: #fff;
}
.goog-logo-link,
.goog-logo-link:link,
.goog-logo-link:visited,
.goog-logo-link:hover,
.goog-logo-link:active {
  color: white;
}
@media only screen and (max-width: 1200px) {
  #google_translate_element {
    padding: 0px 20px;
  }
  .goog-te-combo {
    font-family: "Nunito";
  }
  .goog-te-gadget {
    color: #fff;
  }
  .goog-logo-link,
  .goog-logo-link:link,
  .goog-logo-link:visited,
  .goog-logo-link:hover,
  .goog-logo-link:active {
    color: white;
  }
}
:root {
  --primary: #1e90ff;
  --secondary: rgb(76, 87, 229);
  --tertiary: #663399;
  --white: #fefefe;
}

ul,
li {
  list-style-type: none;
}

a {
  text-decoration: none !important;
}
