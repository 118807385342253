::-webkit-scrollbar {
  display: none;
}

.carousel-inner {
  width: 100%;
  margin: auto;
  margin-bottom: 20px;
}

.dashboard-profile-container {
  background-color: #f5f6fa;
  width: 100%;
  min-height: 100vh;
  padding: 20vh 120px 50px;
}

.dashboard-profile-first-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
}

.dashboard-profile-first-section-first-div p:first-child {
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 1rem;
  color: #8094ae;
  font-weight: 400;
  position: relative;
}

.dashboard-profile-first-section-first-div p:nth-child(3) {
  font-family: Roboto;
  color: #526484;
}

.dashboard-profile-first-section-first-div h2 {
  font-size: 3rem;
  letter-spacing: -0.03em;
  font-weight: 400 !important;
  font-family: Nunito, sans-serif;
  color: #364a63;
}

.dashboard-profile-first-section-first-div button {
  margin-left: 20px;
  border: 1px solid #e5e9f2;
  font-weight: 700;
  color: #000;
  background-color: #fff;
  outline: none;
  padding: 8px 20px;
  font-size: 0.85rem;
  font-family: "Nunito";
  letter-spacing: 0.02em;
  color: #101924;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.dashboard-profile-first-section-first-div button:hover {
  background-color: #798bff49;
}

.dashboard-profile-first-section-second-div {
  width: 290px;
  max-width: 100%;
  position: relative;
  padding: 1rem 3rem;
  border-radius: 4px;
  border: 1px solid #dbdfea;
  background: #fff;
  border-bottom: 4px solid #6576ff;
}

.dashboard-profile-first-section-second-div p:first-child {
  margin-bottom: 0.5rem;
  display: block;
  font-size: 13px;
  color: #8094ae;
  font-family: "Nunito";
}

.dashboard-profile-first-section-second-div h6 {
  color: #6576ff;
  font-weight: 400;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  letter-spacing: -0.01em;
  font-family: "Nunito", sans-serif;
  line-height: 1.1;
}

.dashboard-profile-first-section-second-div a {
  color: #8094ae !important;
  font-size: 0.85rem;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  line-height: 1;
  text-decoration: none;
}
.dashboard-profile-first-section-second-div a span {
  padding-left: 10px;
}

.dashboard-profile-first-section-second-div aside {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #8094ae;
  position: absolute;
  right: 0px;
  top: 10px;
}

.dashboard-profile-second-section {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  padding: 1.25rem;
  width: 100%;
  margin: 20px 0px;
}

.dashboard-profile-second-section p {
  font-size: 13px;
  margin-top: 10px;
  color: #526484;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 2rem);
  font-family: Nunito, sans-serif;
}

.dashboard-profile-third-section,
.dashboard-profile-fourth-section,
.dashboard-profile-fifth-section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.dashboard-profile-third-section div,
.dashboard-profile-fourth-section div {
  padding: 1.5rem;
  flex: 0 0 33.33333%;
  max-width: 30%;
  border: 1px solid #dbdfea;
  border-radius: 4px;
  margin: 20px 0px;
  background-color: #fff;
}

.dashboard-profile-third-section div:first-child {
  background: #2c3782;
  border-color: #2c3782;
  color: #fff;
  border-bottom: 4px solid #2c3782;
}

.dashboard-profile-third-section div h6 {
  color: #c4cefe;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

.dashboard-profile-third-section div:nth-child(2) h6,
.dashboard-profile-third-section div:nth-child(3) h6 {
  color: #8094ae;
}

.dashboard-profile-third-section div:nth-child(2) p,
.dashboard-profile-third-section div:nth-child(3) p {
  color: #526484;
}

.dashboard-profile-third-section div p {
  font-size: 2.25rem;
  letter-spacing: -0.03em;
  line-height: 1.15em;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-family: "Roboto";
}

.dashboard-profile-third-section div:nth-child(2) {
  background-color: #fff;
  border-bottom: 4px solid #364a63;
}

.dashboard-profile-third-section div:nth-child(3) {
  background-color: #fff;
  border-bottom: 4px solid #1ee0ac;
}

.dashboard-profile-fourth-section p,
.dashboard-profile-fourth-section button {
  width: 100%;
}

.dashboard-profile-fourth-section p span {
  float: right;
  font-weight: 500;
  color: #364a63;
}

.dashboard-profile-fourth-section h6 {
  margin-bottom: 0.75rem;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: #8094ae;
  font-family: "Roboto", sans-serif;
  letter-spacing: -0.01em;
}

.p1 {
  font-size: 1.875rem;
  border-bottom: 2px solid #6576ff;
  padding-bottom: 1.25rem;
  margin-bottom: 1rem;
  display: block;
}

.p4 {
  border-top: 1px solid #dbdfea;
  margin-top: 0.3rem;
  padding-top: 0.55rem;
  font-weight: 700;
}

.p2,
.p3 {
  font-family: "Nunito";
  display: block;
}

.dashboard-profile-fourth-section button {
  background-color: #6576ff;
  border-color: #6576ff;
  color: #fff;
  font-size: 0.9375rem;
  font-weight: 500;
  font-family: "Nunito", sans-serif;
  line-height: 1.25rem;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  margin: 10px 0px;
  transition: 0.3s;
  font-size: 1rem;
}

.dashboard-profile-fourth-section .btn1:hover {
  background-color: #2c3782;
}

.dashboard-profile-fourth-section .btn2 {
  background-color: #fff;
  color: #526484;
}

.div3 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.blue {
  color: #6576ff;
  cursor: pointer;
  display: inline;
}

.dashboard-profile-fifth-section .div {
  padding: 1.5rem;
  flex: 0 0 50%;
  max-width: 50%;
  border: 1px solid #dbdfea;
  border-radius: 4px;
  margin: 20px 0px;
  background-color: #fff;
}

.dashboard-profile-fifth-section div:nth-child(2) {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background: transparent;
}

.dashboard-profile-fifth-section div:first-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: none;
}

.refer-flex {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.refer-flex button {
  color: #fff;
  background-color: #6576ff;
  border-color: #6576ff;
  border: none;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0rem 1.125rem;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  border-radius: 4px;
  transition: 0.3s;
  height: 30px;
}

.refer-flex button:hover {
  background-color: #2c3782;
}

.refer-flex-aside h5 {
  font-size: 1.25rem;
  letter-spacing: -0.01em;
  margin-bottom: 0.5rem;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  line-height: 1.1;
  color: #364a63;
}

.dashboard-profile-fifth-section > div > .link-aside {
  padding: 10px;
  border: 1px solid #dbdfea;
  border-radius: 4px;
  font-family: "Nunito";
  margin: 10px 0px;
}

.dashboard-profile-fifth-section > div > .link-aside > span {
  font-size: 0.8125rem;
}

@media only screen and (max-width: 1200px) {
  .dashboard-profile-container {
    padding: 15vh 20px 50px;
  }
}

@media only screen and (max-width: 800px) {
  .dashboard-profile-first-section-second-div {
    display: none;
  }
  .dashboard-profile-third-section,
  .dashboard-profile-fourth-section,
  .dashboard-profile-fifth-section {
    display: block;
  }
  .dashboard-profile-third-section div,
  .dashboard-profile-fourth-section div,
  .dashboard-profile-fifth-section .div {
    max-width: 100%;
  }
}

@media only screen and (max-width: 550px) {
  .link-aside {
    display: flex;
    align-items: center;
  }

  .link-aside > span {
    float: left;
    max-width: 200px;
    margin: 10px 0px;
    white-space: nowrap;
    overflow: hidden;
  }
  .dashboard-profile-first-section-first-div h2 {
    font-size: 30px;
  }
  .dashboard-profile-first-section-first-div button {
    font-size: 12px;
  }
  .dashboard-profile-first-section-first-div button {
    margin-left: 0px;
  }
}
