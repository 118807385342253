* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito";
  scroll-behavior: smooth;
}

.nunito {
  font-family: "Nunito";
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#google_translate_element {
  width: 100%;
  background-color: #29347a;
  /* height: 60px; */
  color: #fff;
  padding: 0px 120px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  color: #fff;
}
.goog-te-combo {
  font-family: "Nunito";
}
.goog-te-gadget {
  color: #fff;
}
.goog-logo-link,
.goog-logo-link:link,
.goog-logo-link:visited,
.goog-logo-link:hover,
.goog-logo-link:active {
  color: white;
}
@media only screen and (max-width: 1200px) {
  #google_translate_element {
    padding: 0px 20px;
  }
  .goog-te-combo {
    font-family: "Nunito";
  }
  .goog-te-gadget {
    color: #fff;
  }
  .goog-logo-link,
  .goog-logo-link:link,
  .goog-logo-link:visited,
  .goog-logo-link:hover,
  .goog-logo-link:active {
    color: white;
  }
}
:root {
  --primary: #1e90ff;
  --secondary: rgb(76, 87, 229);
  --tertiary: #663399;
  --white: #fefefe;
}

ul,
li {
  list-style-type: none;
}

a {
  text-decoration: none !important;
}

.dashboradnavContainer {
  width: 100%;
  background-color: #29347a;
  height: 60px;
  color: #fff;
  padding: 0px 120px;
  position: fixed;
  top: 45px;
  left: 0;
  z-index: 999;
}

.dashboradnavContainer section:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 60px;
  max-width: 1500px;
  margin: auto;
}

.hide,
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.dashboradnavContainer section:first-child div div {
  justify-content: space-evenly;
  width: 400px;
  align-items: center;
  flex-direction: row;
}

.dashboradnavContainer section:first-child div div a {
  text-decoration: none;
}

.dashboradnavContainer section:first-child div div p {
  margin: 0;
  font-family: Nunito, sans-serif;
  font-weight: 700;
  color: #c4cefe;
  cursor: pointer;
  transition: 0.5s;
  position: relative;
  padding: 20px 0;
}

.dashboradnavContainer section:first-child div div p:hover {
  color: #fff;
}

.dashboradnavContainer section:first-child div div .active {
  color: #fff;
}

.dashboradnavContainer section:first-child div div .active:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: #7f8dff;
}

.dashboradnavContainer section:first-child span {
  cursor: pointer;
  padding: 5px;
  margin-right: 10px;
  color: #fff;
  border-radius: 50%;
  transition: 0.5s;
  display: none;
}

.dashboradnavContainer section:first-child span:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.dashboradnavContainer section:first-child div img {
  width: auto;
  height: 40px;
  cursor: pointer;
}

.dashboradnavContainer section:first-child div:nth-child(2) p {
  font-size: 1rem;
  font-weight: 900;
  color: #fff;
  cursor: pointer;
  transition: 0.5s;
  width: 40px;
  height: 40px;
  background-color: #798bff;
  margin: 0;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.dashboradnavContainer section:first-child div:nth-child(2) {
  height: 60px;
  display: flex;
  align-items: center;
}

.user-info {
  margin-left: 1rem;
  height: 60px;
  margin-top: -25px;
  cursor: pointer;
}

.user-status-verified {
  color: #1ee0ac;
}

.user-status {
  font-size: 11px;
  font-weight: 500;
}

.user-name {
  color: #c4cefe;
  font-size: 12px;
  line-height: 1px;
  font-weight: 700;
  margin-top: -20px;
  font-family: Nunito, sans-serif;
}

[class*="dropdown-indicator"]:after {
  border: none !important;
  font-family: "Nioicon";
  content: "^";
  margin-left: 0.25rem;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.nk-quick-nav .dropdown-menu {
  margin-top: 11px;
  margin-right: -6px;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  text-align: left;
  min-width: 180px;
  padding: 0 0;
  margin: 0.125rem 0 0;
  font-size: 0.8125rem;
  color: #526484;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5e9f2;
  border-radius: 4px;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.icon-status {
  position: relative;
  display: inline-flex;
  margin: 0px 20px;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  transition: 0.5s;
}

.icon-status-2 {
  position: relative;
  display: inline-flex;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  transition: 0.5s;
}

.icon-status:hover,
.icon-status-2:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.icon-status:after {
  position: absolute;
  border-radius: 50%;
  right: 10px;
  top: 10px;
  height: 10px;
  width: 10px;
  border: 2px solid #fff;
  content: "";
  background: #09c2de;
}

.user {
  display: flex;
  height: 10vh;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  .dashboradnavContainer {
    padding: 0px 20px;
  }
}

@media only screen and (max-width: 900px) {
  .dashboradnavContainer section:first-child span {
    display: block;
  }
  .hide {
    display: none;
  }
}

@media only screen and (max-width: 550px) {
  .hideIcon {
    display: none;
  }
  .flex-2 {
    flex-direction: row-reverse;
  }
}

.plan-container {
  text-align: center;
  background-color: #f5f6fa;
  padding: 15vh 120px 50px;
}

.cards, #cardsnotres {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 30px;
}

#cardsres {
  display: none;
}

.cards .card {
  background-color: white;
  padding-top: 30px;
  border-radius: 5px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
}

.cards .card .top {
  display: flex;
  justify-content: space-between;
  padding: 25px 80px;
  border-bottom: 1px solid rgba(209, 206, 206, 0.5);
}

.card p {
  font-size: 15px;
}

.card a {
  background-color: #f5f6fa;
  color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  padding: 3px 10px;
  font-size: 15px;
  display: block;
  margin: 20px 50px 0px;
}

.card a:hover {
  color: rgba(0, 0, 0, 0.8);
}

.plan-container ul {
  padding-left: 50px !important;
  padding: 30px 50px;
}

.plan-container ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.i {
  width: 55%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.i p {
  padding: 0px;
  margin: 0px;
}

.plan-container .button {
  background-color: rgb(75, 135, 214);
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 8px 10px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 70px auto;
  width: 170px;
}

.plan-container .cards .card button {
  background-color: #f5f6fa;
  color: rgba(128, 128, 128);
  border: 1px solid rgba(128, 128, 128, 0.289);
  border-radius: 5px;
  padding: 8px 10px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  width: 170px;
}

.button:hover {
  color: #fff;
}

@media screen and (max-width: 1200px) {

  .card {
    margin-top: 20px;
    background-color: green;
  }
  .plan-container {
    padding: 15vh 20px 50px;
  }
}

@media screen and (max-width: 900px) {
  #cardsres {
    display: block;
    width: 100%;
  }
  #cardsnotres {
    display: none;
  }
}

@media screen and (max-width: 470px) {
  .card a {
    margin: 20px 10px 0px;
  }
  #cardsres {
    display: block;
    width: 100%;
  }
  #cardsnotres {
    display: none;
  }
}

@media screen and (max-width: 370px) {
  .top p {
    font-size: 16px;
  }

  #cardsres {
    display: block;
    width: 100%;
  }
  #cardsnotres {
    display: none;
  }

  .top .x {
    margin: 0px;
  }

  .cards .card .top {
    padding: 25px 20px;
  }

  .plan-container {
    padding: 30px 2%;
  }

  .cards {
    padding-top: 10px;
  }

  .card a {
    margin: 20px 0px 0px;
  }

  .plan-container ul {
    padding-left: 20px !important;
    padding: 30px 20px;
  }

  .button {
    margin: 30px auto;
  }
}

.footer2 {
  display: flex;
  justify-content: space-between;
 
  padding-top: 20px;
  padding-left: 25px;
  border-top: 0.1px solid rgba(128, 128, 128, 0.289);
  background-color: #fff;
}
.footer2 ul {
  display: flex;
  justify-content: space-between;
  padding-right: 25px;
}
.footer2 ul li {
  list-style: none;
  padding-left: 6px;
  margin-right: 10px;
}

@media only screen and (max-width: 600px) {
  .footer2 {
    flex-direction: column-reverse;
    text-align: center;
    padding: 15px 10px 5px 10px;
    margin-top: 0px;
  }
  .footer2 ul {
    width: 100%;
  }
}

.invest-container {
  background-color: #f5f6fa;
  padding: 15vh 120px 50px;
}

.my-plan {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btns {
  display: flex;
}

.btns .button {
  background-color: rgb(91, 91, 230);
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 5px 20px;
  margin-left: 20px;
  font-size: 13px;
  display: block;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease-in-out;
}

/* .btns .button:hover {
  background-color: blue;
} */

.btns .button:last-child {
  background-color: white;
  color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.btns .button:last-child:hover {
  background-color: rgba(91, 91, 230, 0.3);
}

.balance-c {
  background-color: white;
  color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 30px;
  border-radius: 5px;
}

.balance {
  display: flex;
  align-items: center;
}

.plus {
  margin: 0px 30px;
  height: 10vh;
  font-size: 30px;
}

.active-c {
  margin-top: 50px;
}

.activeinvest {
  background-color: white;
  color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px 20px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  position: relative;
}

.activeinvest > .progress {
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fff;
}

.activeinvest > .progress > .progress-inside {
  width: 25%;
  height: 3px;
  background-color: #6576ff;
  border-radius: 0 3px 3px 3px;
}

.x,
.y {
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 50%;
  justify-content: center;
}

.x {
  background-color: #f5f6fa;
}

.y:hover {
  background-color: #f5f6fa;
}

.invest-container p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  padding: 0px;
  margin: 0px;
}

@media screen and (max-width: 1200px) {
  .invest-container {
    padding: 15vh 20px 50px;
  }
}

@media screen and (max-width: 990px) {
  .remove {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .my-plan {
    display: block;
  }

  .btns {
    margin-top: 20px;
  }

  .btns .button {
    margin-left: 0px;
  }

  .btns .button:last-child {
    margin-left: 20px;
  }
}

@media screen and (max-width: 767px) {
  .remove1 {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .remove2 {
    display: none;
  }
  .margin-left {
    margin-left: -50px;
  }
}

@media screen and (max-width: 490px) {
  .balance {
    flex-direction: column;
    align-items: flex-start;
  }
  .plus {
    margin: 0px;
    height: auto;
  }
}

::-webkit-scrollbar {
  display: none;
}

.carousel-inner {
  width: 100%;
  margin: auto;
  margin-bottom: 20px;
}

.dashboard-profile-container {
  background-color: #f5f6fa;
  width: 100%;
  min-height: 100vh;
  padding: 20vh 120px 50px;
}

.dashboard-profile-first-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
}

.dashboard-profile-first-section-first-div p:first-child {
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 1rem;
  color: #8094ae;
  font-weight: 400;
  position: relative;
}

.dashboard-profile-first-section-first-div p:nth-child(3) {
  font-family: Roboto;
  color: #526484;
}

.dashboard-profile-first-section-first-div h2 {
  font-size: 3rem;
  letter-spacing: -0.03em;
  font-weight: 400 !important;
  font-family: Nunito, sans-serif;
  color: #364a63;
}

.dashboard-profile-first-section-first-div button {
  margin-left: 20px;
  border: 1px solid #e5e9f2;
  font-weight: 700;
  color: #000;
  background-color: #fff;
  outline: none;
  padding: 8px 20px;
  font-size: 0.85rem;
  font-family: "Nunito";
  letter-spacing: 0.02em;
  color: #101924;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.dashboard-profile-first-section-first-div button:hover {
  background-color: #798bff49;
}

.dashboard-profile-first-section-second-div {
  width: 290px;
  max-width: 100%;
  position: relative;
  padding: 1rem 3rem;
  border-radius: 4px;
  border: 1px solid #dbdfea;
  background: #fff;
  border-bottom: 4px solid #6576ff;
}

.dashboard-profile-first-section-second-div p:first-child {
  margin-bottom: 0.5rem;
  display: block;
  font-size: 13px;
  color: #8094ae;
  font-family: "Nunito";
}

.dashboard-profile-first-section-second-div h6 {
  color: #6576ff;
  font-weight: 400;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  letter-spacing: -0.01em;
  font-family: "Nunito", sans-serif;
  line-height: 1.1;
}

.dashboard-profile-first-section-second-div a {
  color: #8094ae !important;
  font-size: 0.85rem;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  line-height: 1;
  text-decoration: none;
}
.dashboard-profile-first-section-second-div a span {
  padding-left: 10px;
}

.dashboard-profile-first-section-second-div aside {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #8094ae;
  position: absolute;
  right: 0px;
  top: 10px;
}

.dashboard-profile-second-section {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  padding: 1.25rem;
  width: 100%;
  margin: 20px 0px;
}

.dashboard-profile-second-section p {
  font-size: 13px;
  margin-top: 10px;
  color: #526484;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 2rem);
  font-family: Nunito, sans-serif;
}

.dashboard-profile-third-section,
.dashboard-profile-fourth-section,
.dashboard-profile-fifth-section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.dashboard-profile-third-section div,
.dashboard-profile-fourth-section div {
  padding: 1.5rem;
  flex: 0 0 33.33333%;
  max-width: 30%;
  border: 1px solid #dbdfea;
  border-radius: 4px;
  margin: 20px 0px;
  background-color: #fff;
}

.dashboard-profile-third-section div:first-child {
  background: #2c3782;
  border-color: #2c3782;
  color: #fff;
  border-bottom: 4px solid #2c3782;
}

.dashboard-profile-third-section div h6 {
  color: #c4cefe;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

.dashboard-profile-third-section div:nth-child(2) h6,
.dashboard-profile-third-section div:nth-child(3) h6 {
  color: #8094ae;
}

.dashboard-profile-third-section div:nth-child(2) p,
.dashboard-profile-third-section div:nth-child(3) p {
  color: #526484;
}

.dashboard-profile-third-section div p {
  font-size: 2.25rem;
  letter-spacing: -0.03em;
  line-height: 1.15em;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-family: "Roboto";
}

.dashboard-profile-third-section div:nth-child(2) {
  background-color: #fff;
  border-bottom: 4px solid #364a63;
}

.dashboard-profile-third-section div:nth-child(3) {
  background-color: #fff;
  border-bottom: 4px solid #1ee0ac;
}

.dashboard-profile-fourth-section p,
.dashboard-profile-fourth-section button {
  width: 100%;
}

.dashboard-profile-fourth-section p span {
  float: right;
  font-weight: 500;
  color: #364a63;
}

.dashboard-profile-fourth-section h6 {
  margin-bottom: 0.75rem;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: #8094ae;
  font-family: "Roboto", sans-serif;
  letter-spacing: -0.01em;
}

.p1 {
  font-size: 1.875rem;
  border-bottom: 2px solid #6576ff;
  padding-bottom: 1.25rem;
  margin-bottom: 1rem;
  display: block;
}

.p4 {
  border-top: 1px solid #dbdfea;
  margin-top: 0.3rem;
  padding-top: 0.55rem;
  font-weight: 700;
}

.p2,
.p3 {
  font-family: "Nunito";
  display: block;
}

.dashboard-profile-fourth-section button {
  background-color: #6576ff;
  border-color: #6576ff;
  color: #fff;
  font-size: 0.9375rem;
  font-weight: 500;
  font-family: "Nunito", sans-serif;
  line-height: 1.25rem;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  margin: 10px 0px;
  transition: 0.3s;
  font-size: 1rem;
}

.dashboard-profile-fourth-section .btn1:hover {
  background-color: #2c3782;
}

.dashboard-profile-fourth-section .btn2 {
  background-color: #fff;
  color: #526484;
}

.div3 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.blue {
  color: #6576ff;
  cursor: pointer;
  display: inline;
}

.dashboard-profile-fifth-section .div {
  padding: 1.5rem;
  flex: 0 0 50%;
  max-width: 50%;
  border: 1px solid #dbdfea;
  border-radius: 4px;
  margin: 20px 0px;
  background-color: #fff;
}

.dashboard-profile-fifth-section div:nth-child(2) {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background: transparent;
}

.dashboard-profile-fifth-section div:first-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: none;
}

.refer-flex {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.refer-flex button {
  color: #fff;
  background-color: #6576ff;
  border-color: #6576ff;
  border: none;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  padding: 0rem 1.125rem;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  border-radius: 4px;
  transition: 0.3s;
  height: 30px;
}

.refer-flex button:hover {
  background-color: #2c3782;
}

.refer-flex-aside h5 {
  font-size: 1.25rem;
  letter-spacing: -0.01em;
  margin-bottom: 0.5rem;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  line-height: 1.1;
  color: #364a63;
}

.dashboard-profile-fifth-section > div > .link-aside {
  padding: 10px;
  border: 1px solid #dbdfea;
  border-radius: 4px;
  font-family: "Nunito";
  margin: 10px 0px;
}

.dashboard-profile-fifth-section > div > .link-aside > span {
  font-size: 0.8125rem;
}

@media only screen and (max-width: 1200px) {
  .dashboard-profile-container {
    padding: 15vh 20px 50px;
  }
}

@media only screen and (max-width: 800px) {
  .dashboard-profile-first-section-second-div {
    display: none;
  }
  .dashboard-profile-third-section,
  .dashboard-profile-fourth-section,
  .dashboard-profile-fifth-section {
    display: block;
  }
  .dashboard-profile-third-section div,
  .dashboard-profile-fourth-section div,
  .dashboard-profile-fifth-section .div {
    max-width: 100%;
  }
}

@media only screen and (max-width: 550px) {
  .link-aside {
    display: flex;
    align-items: center;
  }

  .link-aside > span {
    float: left;
    max-width: 200px;
    margin: 10px 0px;
    white-space: nowrap;
    overflow: hidden;
  }
  .dashboard-profile-first-section-first-div h2 {
    font-size: 30px;
  }
  .dashboard-profile-first-section-first-div button {
    font-size: 12px;
  }
  .dashboard-profile-first-section-first-div button {
    margin-left: 0px;
  }
}

.divform {
  margin-top: 46px;
  background-color: #fff;
}
.form {
  min-height: 100vh;
  position: relative;
  width: 40%;
  margin: auto;
  padding: 10px;
  padding-top: 20px;
  font-family: "Nunito", sans-serif;
}
.nk-block-title {
  font-family: 700;
}
.logoContainer {
  /* text-align: center;/ */
  cursor: pointer;
  margin: 25px;
}
.textfield {
  border: 0.5px solid #8091a758;
  border-radius: 8px;
  padding: 30px;
}
.label {
  font-weight: 500;
  cursor: pointer;
}
.inputField {
  padding: 10px;
}
.inputField::-webkit-input-placeholder {
  color: #c0cee7;
}
.inputField::placeholder {
  color: #c0cee7;
}
.checkboxbotton form-check-label {
  cursor: pointer;
}
.checkboxbotton {
  display: flex;
  align-items: baseline;
}
.checkboxbotton span {
  color: #798bff;
  margin-left: 4px;
}
.accountsignin {
  text-align: center;
}
.accountsignin span {
  color: #798bff;
}
.checkboxbotton label {
  cursor: pointer;
}
a {
  text-decoration: none;
}
/* @media only screen and (max-width: 900px) {
  .textfield {
    width: 100%;
    margin: 0px;
  }
} */
@media only screen and (max-width: 950px) {
  .form {
    min-height: 100vh;
    position: relative;
    width: 60%;
    margin: auto;
    padding: 10px;
    font-family: "Nunito";
  }
}

@media only screen and (max-width: 768px) {
  .form {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .form {
    width: 100%;
    padding: 20px;
  }
  .textfield {
    padding: 10px;
  }
  .logoContainer {
    text-align: center;
  }
  .inputField {
    padding: 5px;
  }
  .inputField::-webkit-input-placeholder {
    /* height: calc(2.625rem + 2px); */
    /* padding: 0px 10px; */
    font-size: 0.9375rem;
    line-height: 1.25rem;
  }
  .inputField::placeholder {
    /* height: calc(2.625rem + 2px); */
    /* padding: 0px 10px; */
    font-size: 0.9375rem;
    line-height: 1.25rem;
  }
}

@media only screen and (max-width: 320px) {
  .form {
    width: 100%;
    padding: 10px;
  }
}

nav {
  position: fixed;
  width: 100%;
  height: 15vh;
  padding: 15px 10%;
  box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.7);
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  top: 45px;
}

nav .logo img {
  width: 80px;
}

nav .menu {
  display: none;
  cursor: pointer;
}

nav .menu .menu-icon {
  font-size: 30px;
}

nav .links,
nav .links-active {
  display: flex;
  align-items: center;
}

nav .links li,
nav .links-active li {
  margin: 10px 20px -10px;
}

nav .links li a,
nav .links-active li a {
  font-size: 20px;
  color: black;
}

nav .links li a:hover,
nav .links-active li a:hover {
  font-size: 20px;
  color: var(--secondary);
}

nav .links li:last-of-type,
nav .links-active li:last-of-type {
  background-color: var(--secondary);
  padding: 8px 25px;
  border-radius: 10px;
  transition: 0.5s ease-in-out;
}

nav .links li:last-of-type:hover,
nav .links-active li:last-of-type:hover {
  background-color: black;
}

nav .links li:last-of-type a,
nav .links-active li:last-of-type a {
  color: #fff;
}

@media screen and (max-width: 960px) {
  nav .menu {
    display: block;
  }

  nav .links {
    position: absolute;
    left: 0px;
    top: -55vh;
    padding: 0px 0px 25px;
    display: block;
    width: 150%;
    background-color: white;
    transition: 0.3s;
    z-index: -1;
  }

  nav .links-active {
    position: absolute;
    left: 0px;
    top: 15vh;
    padding: 0px 0px 25px;
    display: block;
    width: 150%;
    background-color: white;
    transition: 0.3s;
    z-index: -1;
  }

  nav .links li,
  nav .links-active li {
    padding: 5px;
    margin-left: 0px;
  }

  nav .links li:hover,
  nav .links-active li:hover {
    background-color: rgba(91, 91, 230, 0.3);
  }

  nav .links li:last-of-type,
  nav .links-active li:last-of-type {
    padding: 8px 25px;
    margin-top: 20px;
    margin-left: 1%;
    width: 105px;
  }
}

@media screen and (max-width: 1400px) {
  nav {
    padding: 15px 5%;
  }
}

@media screen and (max-width: 1200px) {
  nav {
    padding: 15px 1%;
  }
}

@media screen and (max-width: 600px) {
  nav .logo img {
    width: 60px;
  }

  nav {
    position: fixed;
    width: 100%;
    height: 13vh;
    padding: 15px 20px;
    box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
  }

  nav .links-active {
    position: absolute;
    left: 0px;
    top: 13vh;
    padding: 0px 0% 20px;
    width: 150%;
    background-color: #fff;
    transition: 0.3s;
    z-index: -1;
  }
}

.car-image {
  margin-top: 15vh;
}

.typed-text {
  color: #fff;
  font-size: 3rem;
  text-align: center;
  display: flex;
  padding-top: 50vh;
  justify-content: center;
}

.banner-p {
  color: #fff;
  font-size: 1.5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 10px 100px;
}

.h-links {
  text-align: center;
  margin-top: 20px;
}

.h-links a {
  color: #fff;
  border: 1px solid #fff;
  margin: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  transition: 00.5s ease-in-out;
}

.h-links a:hover {
  background-color: var(--secondary);
  border: 1px solid var(--secondary);
  color: #fff;
}

.headerSlides {
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-attachment: fixed;
  background-position: center right;
  background-repeat: no-repeat;
  -webkit-animation-name: fade;
          animation-name: fade;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.headerSlides2 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(/static/media/cryptoImg2.576708b3.jpeg);
}

.headerSlides3 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(/static/media/cryptoImg3.6c609c46.jpg);
}

.headerSlides1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(/static/media/cryptoImg.5dbabc9e.jpeg);
}

.header {
  position: relative;
}

.header-cards {
  position: absolute;
  bottom: -70px;
  z-index: 1;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  width: 1000px;
}

.header-cards .cards {
  background-color: #fff;
  display: flex;
  padding: 30px 30px 10px 10px;
  border-radius: 10px;
}

.cards:nth-child(2) {
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: rgb(231, 228, 228);
}

.header-cards .cards img {
  width: 60px;
  height: 50px;
}

.header-cards .cards div {
  margin-left: 20px;
}

.header-cards .cards div p {
  text-align: justify;
}

.header-cards .cards div h1 {
  color: var(--secondary);
}

.carousel-item img {
  margin-left: 0px;
}

.header .carousel-control-next,
.header .carousel-control-prev {
  background-color: rgba(0, 0, 0, 0.2) !important;
  height: 75px !important;
  width: 75px !important;
  font-weight: bold !important;
  padding: 30px !important;
  border-radius: 50% !important;
  margin-top: 37% !important;
}

.header .carousel-control-next {
  margin-right: 10px !important;
}

.carousel-control-prev {
  margin-left: 10px !important;
}

.carousel-indicators {
  display: none !important;
}
.carousel-caption {
  text-align: left !important;
  width: 500px;
  height: 300px;
  position: absolute;
  top: 40% !important;
  left: 10% !important;
}

.carousel-caption .top {
  height: 250px;
}

.carousel-caption h1 {
  font-size: 50px;
  font-weight: bold;
}

.carousel-caption a {
  color: #fff;
  border: 1px solid var(--tertiary);
  padding: 10px 25px;
  margin-right: 25px;
  border-radius: 50px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.carousel-caption a:first-child {
  background-color: rgb(29, 28, 28);
}

.carousel-caption a:hover {
  color: #fff;
  background-color: var(--secondary);
  transition: all 0.5s ease-in-out;
}

@media screen and (max-width: 600px) {
  .car-image {
    margin-top: 12vh;
  }
}

@media screen and (max-width: 1200px) {
  .header-cards {
    display: block;
    width: 98%;
    margin-left: 0px;
    top: 510px;
    background-color: transparent;
    box-shadow: none;
  }

  .header-cards .cards {
    background-color: white;
    width: 100%;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }

  .header-cards .cards:first-child {
    border-bottom: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .header-cards .cards:nth-child(2) {
    border-radius: 0px;
    border-left: none;
    border-right: none;
  }

  .header-cards .cards:last-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}

@media screen and (max-width: 800px) {
  .carousel-caption div {
    margin-left: 55%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 100%;
  }

  .header .carousel-control-next,
  .header .carousel-control-prev {
    height: 30px !important;
    width: 30px !important;
    font-weight: bold !important;
    padding: 10px !important;
    border-radius: 50% !important;
    margin-top: 45% !important;
  }

  .carousel-caption .top {
    height: 180px;
  }

  .carousel-caption h1 {
    font-size: 30px;
    font-weight: bold;
  }

  .carousel-caption p {
    font-size: 18px;
  }

  .carousel-caption a {
    color: #fff;
    border: 1px solid var(--tertiary);
    padding: 10px 25px;
    margin-right: 25px;
    border-radius: 50px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }

  .typed-text {
    font-size: 2.5rem;
  }

  .h-links a {
    margin: 8px;
    padding: 8px 18px;
  }
}

@media screen and (max-width: 700px) {
  .carousel-caption div {
    margin-left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 100%;
  }
}

@media screen and (max-width: 520px) {
  .header img {
    height: 500px;
  }
  .carousel-caption div {
    margin-left: 55%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 100%;
  }

  .carousel-caption p {
    width: 70%;
  }

  .carousel-caption a {
    padding: 10px 15px;
    font-size: 16px;
  }

  .header .carousel-control-next,
  .header .carousel-control-prev {
    margin-top: 65% !important;
  }
}

@media screen and (max-width: 450px) {
  .header img {
    height: 500px;
  }
  .carousel-caption div {
    margin-left: 55%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    height: 100px;
    width: 100%;
  }

  .carousel-caption p {
    width: 60%;
  }

  .carousel-caption a {
    padding: 5px 15px;
    font-size: 16px;
    top: 50px;
  }

  .header .carousel-control-next,
  .header .carousel-control-prev {
    margin-top: 65% !important;
  }

  .typed-text {
    font-size: 2rem;
  }

  .h-links a {
    margin: 5px;
    padding: 5px 15px;
  }

  .banner-p {
    padding: 10px 20px;
    font-size: 1em;
  }
}

.about-container {
  padding: 10px 0px;
  width: 100%;
  background-color: var(--secondary);
  position: relative;
}

.no-fade {
  opacity: 0;
  margin-top: 50px;
}

.div-fade {
  opacity: 1;
  transition: all 1.5s;
  margin-top: 0px;
}

/* .about-container .image {
    display: block;
    margin-top: 100px;
    margin-left: -230px;
    margin-right: 10px;
} */

.about-about,
.about-text {
  /* margin-bottom: 50px; */
  text-align: center;
  padding: 10px 10%;
  color: #fff;
}

.about-text {
  padding: 10px 0px;
}

.about-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}

.about-info div {
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); */
  padding: 50px 10px 20px;
  text-align: center;
  position: relative;
  /* background-color: #fff; */
  color: #fff;
}

.about-info div h5 {
  margin-top: 20px;
}

.about-info div img {
  position: absolute;
  top: -35px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-radius: 50%;
  display: block;
  width: 80px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  padding: 10px 5px;
}

.philosophy-container {
  /* margin-top: 50px; */
  padding: 50px 10% 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
}

.philosophy-container .philosophy p {
  text-align: justify;
}

.philosophy-container .start {
  text-align: center;
  padding: 50px 20px;
  margin: 0px 0px 30px 50px;
  color: #fff;
  background: linear-gradient(var(--primary) 0%, var(--secondary) 50%);
}

.philosophy-container .start p {
  margin: 20px 0px 30px;
}

.philosophy-container .start a {
  color: #fff;
  border: 1px solid var(--tertiary);
  padding: 10px 25px;
  border-radius: 50px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.philosophy-container .start a:hover {
  color: #fff;
  background-color: rgb(29, 28, 28);
  transition: 0.5s ease-in-out;
}

.philosophy-container img {
  position: absolute;
  top: -50px;
  right: -50vh;
  height: 100px;
  display: block;
  width: 900px;
  height: 500px;
  z-index: -1;
}

.how-container {
  padding: 50px 10%;
  /* color: #fff; */
  text-align: center;
  /* background: linear-gradient(var(--primary) 0%, var(--secondary) 50%); */
  background-color: #fff;
  position: relative;
  color: rgb(17, 17, 37);
}

.how-container .how-info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 60px 30px 20px;
}

.how-container .how-info img {
  width: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1400px) {
  .about-container {
    padding: 20px 5% 10px 0px;
  }

  .philosophy-container .start {
    margin: 0px 0px 30px 50px;
  }

  .philosophy-container {
    padding: 50px 5%;
  }
}

@media screen and (max-width: 1200px) {
  .about-container {
    display: block;
    padding: 30px 1% 0px;
  }

  .about-container .image {
    width: 100%;
    margin-left: 0px;
  }

  .philosophy-container {
    display: block;
    padding: 50px 10%;
    margin-top: 0px;
  }

  .philosophy-container .philosophy {
    margin-top: 0px;
  }

  .philosophy-container .start {
    margin: 0px;
  }

  .philosophy-container img {
    display: none;
  }
}

@media screen and (max-width: 850px) {
  .about-about {
    margin-top: 20px;
  }

  .about-info {
    display: block;
  }

  .how-container .how-info {
    display: block;
  }
}

.mobile {
  display: none;
}

.services-container {
  padding: 100px 10%;
  text-align: center;
  color: #fff;
}

.service {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 100px;
}

.service div {
  margin: 50px;
  text-align: justify;
}

.service div h2 {
  /* color: var(--secondary); */
  color: #fff;
}

.tool-container {
  padding: 0px 10%;
  position: relative;
  /* background: linear-gradient(var(--primary) 0%, var(--secondary) 50%); */
  background-color: rgba(256, 256, 256, 0.7);
  color: rgb(17, 17, 37);
}

.tool-container .tool-first {
  padding: 150px 0px;
  /* color: #fff; */
  display: grid;
  grid-gap: 50px;
  grid-template-columns: repeat(2, 1fr);
}

.tool-container .tool-first p {
  text-align: justify;
}

.tool-container .tool-first img {
  width: 100%;
}

.tool-container .tool {
  position: absolute;
  bottom: -70px;
  z-index: 1;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(2, 1fr);
  border-radius: 10px;
  padding: 0px 10%;
  width: 100%;
}

.tool-container .tool .tool-cont {
  background-color: #fff;
  display: flex;
  padding: 30px 30px 10px 10px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  /* width: 100%; */
}

.tool-container .tool .tool-cont img {
  width: 60px;
  height: 50px;
}

.tool-container .tool .tool-cont div {
  margin-left: 20px;
}

.tool-container .tool .tool-cont div p {
  text-align: justify;
}

.more-container {
  padding: 150px 10% 100px;
  text-align: center;
  color: #fff;
}

.more-container-grid {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(3, 1fr);
}

.more-container-grid div {
  padding: 50px 50px 30px;
  border-radius: 20px;
  border: 1px solid rgb(231, 228, 228);
  transition: 0.5s ease-in-out;
}

.more-container-grid div:hover {
  color: #fff;
  background-color: rgba(256, 256, 256, 0.3);
}

.more-container-grid div:hover h1 {
  color: black;
}

.more-container-grid div p {
  line-height: 50px;
}

.more-container-grid div a {
  display: block;
  color: #fff;
  padding: 15px 0px;
  width: 200px;
  margin: auto;
  border-radius: 50px;
  background: linear-gradient(var(--primary) 0%, var(--secondary) 50%);
}

.more-container-grid div h1 {
  margin-top: 20px;
  font-size: 3rem;
  color: var(--secondary);
}

.more-sub-container {
  padding: 50px 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  /* margin-bottom: 50px; */
  /* background: linear-gradient(var(--primary) 0%, var(--secondary) 50%); */
  background-color: rgba(0, 0, 0, 0.2);
}

.more-sub-container a {
  color: #fff;
  border: 1px solid var(--tertiary);
  padding: 10px 25px;
  margin-right: 25px;
  border-radius: 50px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.more-sub-container a:hover {
  color: #fff;
  background-color: rgb(29, 28, 28);
  transition: 0.5s ease-in-out;
}

@media screen and (max-width: 1400px) {
  .services-container {
    padding: 100px 5%;
    text-align: center;
  }
  .service {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
  }

  .service div {
    margin: 30px;
  }
  .tool-container {
    padding: 0px 5%;
  }
  .tool-container .tool {
    padding: 0px 5%;
  }
  .more-sub-container {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media screen and (max-width: 1200px) {
  .services-container {
    padding: 100px 1%;
    text-align: center;
  }
  .tool-container .tool {
    padding: 0px 1%;
    bottom: -265px;
    display: block;
  }
  .tool-container .tool .tool-cont {
    padding: 30px 30px 5px 5px;
    margin-top: 10px;
  }

  .tool-container .tool-first {
    display: block;
  }

  .tool-container .tool-first img {
    margin-left: 0px;
  }

  .more-sub-container {
    padding-left: 1%;
    padding-right: 1%;
  }
}

@media screen and (max-width: 1200px) {
  .more-container {
    padding: 300px 10% 300px;
    text-align: center;
  }
  .more-container-grid {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(2, 1fr);
  }

  .more-container {
    padding: 300px 10% 30px;
    text-align: center;
  }
}

@media screen and (max-width: 800px) {
  .more-container-grid {
    display: block;
  }

  .more-container-grid div p {
    line-height: 40px;
  }

  .more-container-grid div {
    margin-top: 30px;
  }

  .service {
    display: block;
  }

  .service h2 {
    text-align: center;
  }

  .service div {
    margin: 0px;
  }

  .more-sub-container {
    display: block;
    text-align: center;
  }

  .more-sub-container a {
    margin-right: 0px;
  }

  .mobile,
  .desktop-m {
    display: block;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .desktop {
    display: none;
    text-align: center;
  }
}

@media screen and (max-width: 560px) {
  .tool-container {
    margin-bottom: 200px;
  }

  .tool-container .tool {
    bottom: -460px;
  }

  .tool-container .tool .tool-cont {
    background-color: #fff;
    display: block;
    text-align: center;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 20px 10px 0px;
  }

  .tool-container .tool .tool-cont div {
    margin: 0px;
    margin-top: 20px;
    padding-bottom: 1px;
  }

  .tool-container .tool .tool-cont div p {
    text-align: justify;
  }

  .service img {
    width: 300px;
  }
}

@media screen and (max-width: 400px) {
  .more-container-grid div {
    padding: 50px 20px 20px;
  }

  .more-container-grid div a {
    display: block;
    color: #fff;
    padding: 10px 0px;
    width: 150px;
    margin: auto;
    border-radius: 50px;
    background: linear-gradient(var(--primary) 0%, var(--secondary) 50%);
  }
}

@media screen and (max-width: 360px) {
  .service img {
    width: 250px;
  }
}

.servSlides {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/static/media/cryp3.4c761595.jpg);
  width: 100%;
  min-height: 75vh;
  background-size: cover;
  background-attachment: fixed;
  background-position: center right;
  background-repeat: no-repeat;
  -webkit-animation-name: fade;
          animation-name: fade;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

footer {
  /* background: linear-gradient(var(--primary) 0%, var(--secondary) 50%); */
  background-color: rgb(17, 17, 37);
  color: #fff;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 100px 10% 20px;
}

footer li {
  padding: 0px 10px 10px;
}

footer a {
  color: #fff;
  font-size: 20px;
}

footer a:hover {
  color: #fff;
}

.mail-icon {
  font-size: 40px;
  background-color: black;
  border-radius: 50%;
  padding: 5px;
  margin-right: 20px;
}

@media screen and (max-width: 960px) {
  footer {
    display: block;
    padding: 80px 5% 10px;
  }

  footer ul,
  footer li {
    margin-left: 0px !important;
    padding-left: 0px;
  }

  footer li {
    padding-bottom: 30px;
  }

  footer li:last-child {
    padding-bottom: 0px;
  }

  footer div,
  footer ul {
    margin-bottom: 50px;
  }
}

.invest-container {
  background-color: #f5f6fa;
  padding: 15vh 120px 50px;
}

.my-plan {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .my-plan a,
.my-plan a:hover {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.5);
} */

.balance-c {
  background-color: white;
  color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  margin-top: 30px;
  border-radius: 5px;
}

.balance-c p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  padding: 0px;
  margin: 0px;
}

.balance-s {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.balance {
  display: flex;
  align-items: center;
}

.plus {
  margin: 0px 30px;
  height: 10vh;
  font-size: 30px;
}

.table {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px;
  border: none !important;
}

.table ul {
  border: none;
  margin: 0px !important;
  padding: 0px !important;
}

.table > :not(:first-child) {
  border: none !important;
}

.table li {
  display: flex;
  justify-content: space-between;
  margin: 0px !important;
  padding: 5px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.table li:last-child {
  border-bottom: none;
}

.x,
.y {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 13px;
  border-radius: 50%;
  justify-content: center;
  color: #6576ff;
  width: 35px;
  height: 35px;
}

.x {
  background-color: #6577ff3f;
  transition: 0.5s ease-in-out;
}

.x:hover {
  background-color: rgba(91, 91, 230, 0.3);
}

.y:hover {
  background-color: #f5f6fa;
}

.invest-container .my-plan span {
  color: #fff;
  background-color: var(--secondary);
  padding: 3px;
  border-radius: 3px;
  font-size: 13px;
}

.t-container {
  background-color: white;
  padding: 30px 20px 20px;
  border-radius: 5px;
}

table {
  background-color: white;
  color: rgba(0, 0, 0, 0.6);
  padding: 30px;
  width: 100%;
}

tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

th {
  background-color: #f5f6fa;
  padding: 6px;
}

td {
  padding: 5px;
}

.adminBtn {
  color: #fff;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 13px;
  border: none;
  outline: none;
  border-radius: 4px;
  margin-left: 20px;
  padding: 15px 30px;
  letter-spacing: 0.02em;
  transition: all .5s;
}

.adminCancelBtn {
  background-color: #e85347;
}

.adminNotMatureBtn, .adminMatureBtn {
  background-color: #09c2de;
}

.adminBtn:hover {
  -webkit-transform: scale(0.97);
          transform: scale(0.97);
}

@media screen and (max-width: 1200px) {
  .table {
    display: block;
  }
  .invest-container {
    padding: 15vh 20px 50px;
  }
}

@media screen and (max-width: 560px) {
  .balance-s {
    display: block;
  }

  .balance-c {
    padding: 20px;
    margin-top: 20px;
  }

  .invest-container .right {
    margin-top: 20px;
  }
  .my-plan {
    display: block;
  }
  .balance {
    display: flex;
    align-items: flex-start;
  }
  .plus {
    margin: 0px;
    height: auto;
  }
  .adminBtns {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .adminBtn {
    padding: 10px 20px;
    margin-left: 0px;
    font-size: 10px;
  }
}

body {
  background-color: #f5f6fa;
}
.withdrawcontainer {
   padding: 15vh 120px 50px;
  margin-bottom: 0;
  width: 100%;
}
.withdrawcontainer h1 {
  margin-bottom: 50px;
  font-size: 2.5rem;
  letter-spacing: -0.03em;
  font-weight: 400 !important;
}

.withdrawcontainer p {
  font-size: 0.875rem;
  font-weight: 500;
  color: rgb(52, 67, 87);
}

.amount {
  display: grid;
  grid-template-columns: 16.7% 16.7% 16.7% 16.7% 16.7% 16.7%;
  width: 60%;
}
.amount p {
  /* background-color: blue;
  margin-right: auto;
  padding: 1rem ; */
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #dbdfea;
  background: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 1.25rem;
  padding: 1rem;
  width: 90%;
  margin-bottom: 0;
  margin-right: 10px;
  transition: all 0.3s;
}

.amount .withdrawactivep {
  border: 1px solid #6576ff;
  background: #6576ff;
  color: #fff;
}
.formwithdraw form {
  margin-left: 0;
  padding-left: 0;
  width: 60%;
}
.inputField {
  /* padding: 20px; */
  /* padding: 1rem 4rem 1.25rem 1.5rem; */
  /* height: 3.75rem; */
  font-size: 1.25rem;
  color: #8094ae;
  border-color: #dbdfea;
}

@media only screen and (max-width: 768px) {
  /* .form {
      width: 80%;
    } */
}

@media only screen and (max-width: 600px) {
  .withdrawcontainer h1 {
    margin-bottom: 20px;
  }
  .withdrawcontainer {
    padding: 80px 30px 25px 30px;
  }
  .withdrawcontainer p {
    line-height: 1.5rem;
    color: #8094ae;
    width: 90%;
    padding: 6px;
  }
  .amount {
    grid-template-columns: 33.3% 33.3% 33.3%;
    width: 100%;
    grid-column-gap: 3px;
    -webkit-column-gap: 3px;
            column-gap: 3px;
    grid-row-gap: 10px;
    row-gap: 10px;
  }
}

body {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.65;
  color: rgb(82, 100, 132);
  text-align: left;
  background-color: #f5f6fa;

  margin: 0;
}

.planSelect {
  color: rgb(82, 100, 132);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

/* REWRITING THE CSS FOR THE INVESTMENT SECTION */

.investcontainer {
  padding: 100px 60px 50px;
  margin-bottom: 0;
  width: 100%;
  min-height: 100vh;
}
.investcontainer h1 {
  margin-bottom: 50px;
  font-size: 2.5rem;
  letter-spacing: -0.03em;
  font-weight: 400 !important;
}

.investcontainer p {
  font-size: 0.875rem;
  font-weight: 500;
  color: rgb(52, 67, 87);
}

.investamount {
  display: grid;
  grid-template-columns: 16.7% 16.7% 16.7% 16.7% 16.7% 16.7%;
  width: 94%;
  grid-column-gap: 6px;
  -webkit-column-gap: 6px;
          column-gap: 6px;
}
.investcontainer p,
.investcontainer label {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #8094ae;
  font-weight: 300;
}
.investamount p {
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #dbdfea;
  background: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 1.25rem;
  padding: 1rem;
  width: 100%;
  margin-bottom: 0;
  margin-right: 10px;
  transition: all 0.3s;
}

.investamount .withdrawactivep {
  border: 1px solid #6576ff;
  background: #6576ff;
  color: #fff;
}
.formwithdraw form {
  margin-left: 0;
  padding-left: 0;
  width: 100%;
}
.inputField {
  font-size: 1.25rem;
  color: #8094ae;
  border-color: #dbdfea;
}

.packagedropdown {
  width: 100%;
  background-color: #fff;
  border: 1px solid #dbdfea;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  position: relative;
}

.popoverInvestment {
  width: 100%;
  background-color: #fff;
  border: 1px solid #dbdfea;
  border-radius: 4px;
}

.popoverInvestment > div {
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
}

/* REWRITING THE CSS FOR THE INVESTMENT SECTION */

.investcontainer span:first-child {
  display: block;
}
.dropdownplan {
  padding: 15px 140px 20px 20px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid rgba(128, 128, 128, 0.404);
  border-radius: 5px;
}

.investment {
  display: flex;
  justify-content: space-between;
}

.investmentinput {
  width: 100%;
}
.investmentdetails {
  border: 1px solid rgba(128, 128, 128, 0.404);
  width: 30%;
  height: 100%;
  /* margin-left: 50px; */
  border-radius: 8px;
  background-color: white;
}
.investcontainer .investnote {
  font-style: italic;
  font-size: 13px;
}
.investmentdetails h6 {
  margin: 15px 25px 15px;
  font-family: Nunito, sans-serif;
  font-weight: 700;
  line-height: 1.1;
  color: #364a63;
}
.investmentdetails .investment_btn {
  background-color: rgb(91, 91, 230);
  color: #fff;
  border-radius: 5px;
  padding: 5px 20px;
  font-size: 13px;
  display: block;
  margin: 20px auto;
  transition: 0.5s ease-in-out;
  border: none;
  outline: none;
}
.button {
  background-color: #f5f6fa;
}
.investmenttable {
  border-bottom: 1px solid rgb(229, 233, 242);
}
.investmenttable ul {
  display: grid;
  grid-template-columns: 50% 50%;
}
.investmenttable ul li {
  /* display: block; */
  /* width: 100%;
  height: 100%;
  margin-right: 30px; */
  margin-bottom: 8px;
  font-size: 13px;
}
.subtext {
  display: block;
  font-size: 13px;
  color: rgb(128, 148, 174);
  font-family: "Nunito", sans-serif, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif;
  margin-right: 0;
  padding-right: 0;
}
.text {
  font-family: "Nunito", sans-serif, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif;
  font-size: 13px;
  color: rgb(54, 74, 99);
  display: block;
  margin-right: 0;
  padding-right: 0;
}
.leadtext {
  font-size: 0.875rem;
  font-weight: 700;
  color: rgb(54, 74, 99);
  display: block;
  margin-right: 0;
  padding-right: 0;
}
.leadtext span {
  margin-left: 0;
  margin-right: 20px;
  padding: 0;
  font-size: 0.875rem;
  font-weight: 700;
  color: rgb(54, 74, 99);
  font-family: Roboto, sans-serif, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif;
}
.amounttoinvest {
  border-bottom: 1px solid rgb(229, 233, 242);
  padding-top: 10px;
}
.total {
  border-bottom: 1px solid rgb(229, 233, 242);
  padding-top: 20px;
  padding-bottom: 6px;
}
.subtexttotal {
  font-size: 0.875rem;
  font-weight: 700;
  color: rgb(54, 74, 99);
  display: block;
  font-family: Roboto, sans-serif, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif;
}


@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 900px) {
  .investment {
    flex-direction: column;
  }
  .investcontainer {
    min-height: 100vh;
    padding: 80px 30px 25px 30px;
  }
  .investcontainer h1 {
    width: 100%;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  .investcontainer p {
    line-height: 1.5rem;
    color: #8094ae;
    line-height: 1.5rem;
    font-weight: 400;
    width: 90%;
    padding: 6px;
  }
  .investamount {
    grid-template-columns: 33.3% 33.3% 33.3%;
    width: 100%;
    grid-column-gap: 3px;
    -webkit-column-gap: 3px;
            column-gap: 3px;
    grid-row-gap: 10px;
    row-gap: 10px;
  }
  .investcontainer .investnote {
    font-size: 10px;
  }
  .investmenttable ul li {
    display: block;
  }

  .row-col {
    display: flex;
    flex-direction: column;
  }

  .investmenttable ul {
    display: block;
  }
  .investmentdetails {
    width: 100%;
  }
  /* .investmenttable {
    display: block;
  } */
}


.general-container {
  padding: 10px 10%;
  font-size: 20px;
  text-align: justify;
  background-color: #fff;
}

.term-container {
  background-color: #fff;
}
.terms-container {
  padding-top: 20vh;
}

.terms-container .list li {
  list-style-type: disc;
  font-size: 16px;
  font-family: "Open Sans";
}
.terms-container p {
  font-size: 16px;
  font-family: "Open Sans";
}

.faq-container {
  text-align: center;
}

.faq-container div {
  padding: 40px 20px 20px;
  margin: 20px 0px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  text-align: justify;
  font-size: 80%;
}

.faq-container div span {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}

.faq-container div span .arrow {
  font-size: 30px;
  color: var(--secondary);
}

.form-container {
  display: flex;
  justify-content: center;
  margin-top: 10vh;
}

.form-container .form-card {
  box-shadow: 4px 2px 20px 0px rgba(0, 0, 0, 0.3);
  padding: 30px;
  width: 35%;
  text-align: center;
  border-radius: 10px;
  background-color: white;
}

.form-container .form-card form {
  box-shadow: 4px 2px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 30px;
}

.form-container .form-card form div {
  margin: 10px;
  border-bottom: 1px solid rgba(223, 223, 223, 0.5);
}

.form-container .form-card form div input,
.form-container .form-card form div textarea {
  width: 100%;
  border: none;
  outline: none;
  padding: 10px;
}

.form-container .form-card form .btn {
  background-color: var(--secondary);
  color: #fff;
  font-size: 20px;
  border-radius: 50px;
  padding: 10px 20px;
  margin-top: 15px;
  transition: 0.5s ease-in-out;
}

.form-container .form-card form .btn:hover {
  background-color: black;
  color: #fff;
}

@media screen and (max-width: 1200px) {
  .general-container {
    padding: 10px 2%;
  }
  .faq-container {
    padding: 0px 5%;
  }
  .faq-container div {
    margin: 20px 10px;
  }
  .faq-container div span {
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .faq-container div span .right {
    display: flex;
    justify-content: right;
  }
  .form-container .form-card {
    width: 50%;
  }
}

@media screen and (max-width: 800px) {
  .general-container {
    padding: 10px 2%;
  }
  .form-container .form-card {
    width: 70%;
  }
  .terms-container {
    padding: 20vh 30px;
  }
}

@media screen and (max-width: 500px) {
  .general-container {
    padding: 10px 2%;
  }
  .form-container .form-card {
    width: 90%;
  }
  .form-container .form-card form {
    padding: 20px 10px 10px;
  }
  .form-container .form-card form div {
    margin: 5px;
    border-bottom: 1px solid rgba(223, 223, 223, 0.5);
  }
  .form-container .form-card form .btn {
    font-size: 16px;
    padding: 8px 18px;
    margin-top: 10px;
    margin-bottom: 10px;
    transition: 0.5s ease-in-out;
  }
  .terms-container {
    padding: 20vh 30px;
  }
}

.adminHomeContainer {
  width: 100%;
  min-height: 100vh;
  background: #f5f6fa;
  padding: 100px 20px;
}

.adminh3 {
  font-size: 1.75rem;
  font-weight: 700;
  color: #364a63;
}

.adminp1 {
  color: #8094ae !important;
}

.divflex1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.divflex1 > article {
  border: 1px solid #dbdfea;
  padding: 1.25rem;
  width: calc(50% - 1rem);
  background-color: #fff;
  border-radius: 4px;
}

.divflex1 > article:nth-child(2) {
  margin: 0px 30px;
}

.divflex1 > article > h6 {
  color: #8094ae;
}

.divflex1 > article > p {
  font-size: 1.5rem;
  color: #364a63;
}

.divflex2 {
  border: 1px solid #dbdfea;
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-color: #fff;
  width: 100%;
}

.divflex2 > article {
  padding: 1.25rem;
}

.divflex2 > article:first-child {
  padding-bottom: 0px;
  border-bottom: 1px solid #dbdfea;
}

.divflex2 > article > h6 {
  font-size: 1.05rem;
  font-weight: 700;
  color: #364a63;
}

.divflex2 > article > p {
  font-size: 12px;
  color: #8094ae;
}

.divflex2 > article > span {
  color: #6576ff;
  border-bottom: 3px solid #6576ff;
  padding-bottom: 0.75rem;
  display: block;
  float: left;
  clear: left;
  font-weight: 700;
}

.linkspan {
  color: #798bff;
}

.divflex2 > article:last-child > p:first-child {
  color: #8094ae;
  font-size: 13px;
  font-weight: 700;
}

.divflex2 > article > div > p:first-child,
.divflex2 > article > div > p:nth-child(3) {
  font-size: 1.25rem;
  line-height: 1.1;
  color: #364a63;
  margin-bottom: 0px;
}

.divflex2 > article > div > p:nth-child(2),
.divflex2 > article > div > p:nth-child(4) {
  font-size: 11px;
  color: #8094ae;
  text-transform: uppercase;
  margin-top: 0.25rem;
  letter-spacing: 0.1em;
}

.table1 {
  display: block;
}

.table2 {
  display: none;
}

@media (max-width: 750px) {
  .divflex1 > article {
    width: 100%;
  }
  .divflex1 > article:nth-child(2) {
    margin: 0px;
  }
  .divflex1 > article {
    margin: 20px 0px;
  }
  .divflex1 {
    display: block;
  }
}

@media (min-width: 576px) {
  .adminHomeContainer {
    padding: 100px 40px;
  }
  .divflex1 > article,
  .divflex2 > article {
    padding: 1.5rem;
  }
  .divflex2 {
    width: 50%;
  }
  .table2 {
    display: block;
  }

  .table1 {
    display: none;
  }
}

.academyContainer {
  background-color: #f5f6fa;
  width: 100%;
  padding: 50px 120px;
}

.academyContentContainer {
  background-color: #f5f6fa;
  width: 100%;
  padding: 0px 120px;
}

.plansCon {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: #fff;
  border-radius: 4px;
}

.titleDivCon {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 60px;
}

.titleDiv {
  padding: 20px;
  width: 25%;
  max-width: 50%;
  height: 150px;
  display: grid;
  place-items: center;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  position: relative;
}

.iconabs {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 70px;
  width: 70px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: none;
  opacity: 0.2;
  transition: all 0.3s ease-in-out;
}

.iconabsactive {
  box-shadow: 0px 0px 20px -10px #181e47;
  opacity: 1;
}

.iconabsicon {
  font-size: 40px;
}

.titleDivActive {
  background-color: #29347a;
  color: #fff;
}

.academyContentHeader {
  width: 100%;
  margin-bottom: 10px;
  background-image: url(/static/media/academy-content.5617893d.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 30vh;
  position: relative;
  margin-top: 60px;
}

.academyContentHeaderAbs {
  padding: 20px 120px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 30vh;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
}

.academyContentHeaderAbs > h4 {
  color: #fff;
}

.academyContentHeaderAbs > p {
  color: grey;
}

.goBack {
  position: fixed;
  right: 20px;
  top: 50%;
  opacity: 0.4;
  color: black;
  width: 70px;
  height: 70px;
  background-color: #29347a28;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px 0px;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  z-index: 1000000;
}

.goBack > p {
  margin: 0;
  font-size: 10px;
}

.goBack:hover {
  opacity: 1;
  background-color: #29347a80;
}

.video-container {
  width: 100%;
  height: 50vh;
  margin-top: 60px;
  position: relative;
}

#colorChange {
  background-color: #29347a11;
}

#colorChange {
  padding: 16px;
}

.abs-video {
  width: 100%;
  height: inherit;
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0vh 120px;
  font-size: 40px;
  font-weight: 900;
  text-transform: uppercase;
}

.video {
  width: 100%;
  height: inherit;
  border: none;
  outline: none;
  object-fit: cover;
}

.psedoDiv {
  position: relative;
  color: #29347a;
  transition: 0.5s;
  cursor: pointer;
  z-index: 2;
  text-align: center;
}

.psedoDiv p,
.psedoDivActive p,
.psedoDivDisabled p {
  margin-bottom: 0;
}

.psedoDivActive {
  position: relative;
  color: white;
  transition: 0.5s;
  cursor: pointer;
  z-index: 2;
  background-color: #29347a;
  text-align: center;
}

.psedoDivDisabled {
  position: relative;
  color: white;
  transition: 0.5s;
  cursor: pointer;
  z-index: 2;
  background-color: #29347a48;
  cursor: no-drop;
  text-align: center;
}

.psedoDiv:hover {
  color: white;
}

.psedoDiv:hover::after {
  background-color: #29347a;
  bottom: 0;
  color: #fff;
}

.psedoDiv::after {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 50%;
  right: 0%;
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  border-radius: 10px;
}

@media only screen and (max-width: 1200px) {
  .academyContainer {
    padding: 5vh 20px 50px;
  }
  .academyContentContainer {
    padding: 0vh 20px 20px;
  }
  .abs-video {
    padding: 0vh 20px;
    font-size: 30px;
  }
}

@media only screen and (max-width: 700px) {
  .abs-video {
    font-size: 25px;
    text-align: center;
    justify-content: center;
  }
  .academyContentHeaderAbs {
    padding: 20px;
  }
  .titleDiv {
    width: 50%;
    height: 100px;
  }
  .titleDiv h3 {
    font-size: 15px;
  }
  .titleDivCon {
    justify-content: space-between;
  }
  .psedoDiv {
    text-align: left;
    padding-left: 20px;
  }

  .psedoDivActive {
    text-align: left;
    padding-left: 20px;
  }

  .psedoDivDisabled {
    text-align: left;
    padding-left: 20px;
  }

  .iconabs {
    height: 40px;
    width: 40px;
  }

  .iconabsicon {
    font-size: 20px;
  }
}

