.footer2 {
  display: flex;
  justify-content: space-between;
 
  padding-top: 20px;
  padding-left: 25px;
  border-top: 0.1px solid rgba(128, 128, 128, 0.289);
  background-color: #fff;
}
.footer2 ul {
  display: flex;
  justify-content: space-between;
  padding-right: 25px;
}
.footer2 ul li {
  list-style: none;
  padding-left: 6px;
  margin-right: 10px;
}

@media only screen and (max-width: 600px) {
  .footer2 {
    flex-direction: column-reverse;
    text-align: center;
    padding: 15px 10px 5px 10px;
    margin-top: 0px;
  }
  .footer2 ul {
    width: 100%;
  }
}
