.invest-container {
  background-color: #f5f6fa;
  padding: 15vh 120px 50px;
}

.my-plan {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btns {
  display: flex;
}

.btns .button {
  background-color: rgb(91, 91, 230);
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 5px 20px;
  margin-left: 20px;
  font-size: 13px;
  display: block;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease-in-out;
}

/* .btns .button:hover {
  background-color: blue;
} */

.btns .button:last-child {
  background-color: white;
  color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.btns .button:last-child:hover {
  background-color: rgba(91, 91, 230, 0.3);
}

.balance-c {
  background-color: white;
  color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 30px;
  border-radius: 5px;
}

.balance {
  display: flex;
  align-items: center;
}

.plus {
  margin: 0px 30px;
  height: 10vh;
  font-size: 30px;
}

.active-c {
  margin-top: 50px;
}

.activeinvest {
  background-color: white;
  color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px 20px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  position: relative;
}

.activeinvest > .progress {
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fff;
}

.activeinvest > .progress > .progress-inside {
  width: 25%;
  height: 3px;
  background-color: #6576ff;
  border-radius: 0 3px 3px 3px;
}

.x,
.y {
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 50%;
  justify-content: center;
}

.x {
  background-color: #f5f6fa;
}

.y:hover {
  background-color: #f5f6fa;
}

.invest-container p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  padding: 0px;
  margin: 0px;
}

@media screen and (max-width: 1200px) {
  .invest-container {
    padding: 15vh 20px 50px;
  }
}

@media screen and (max-width: 990px) {
  .remove {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .my-plan {
    display: block;
  }

  .btns {
    margin-top: 20px;
  }

  .btns .button {
    margin-left: 0px;
  }

  .btns .button:last-child {
    margin-left: 20px;
  }
}

@media screen and (max-width: 767px) {
  .remove1 {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .remove2 {
    display: none;
  }
  .margin-left {
    margin-left: -50px;
  }
}

@media screen and (max-width: 490px) {
  .balance {
    flex-direction: column;
    align-items: flex-start;
  }
  .plus {
    margin: 0px;
    height: auto;
  }
}
