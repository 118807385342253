.invest-container {
  background-color: #f5f6fa;
  padding: 15vh 120px 50px;
}

.my-plan {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .my-plan a,
.my-plan a:hover {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.5);
} */

.balance-c {
  background-color: white;
  color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  margin-top: 30px;
  border-radius: 5px;
}

.balance-c p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  padding: 0px;
  margin: 0px;
}

.balance-s {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.balance {
  display: flex;
  align-items: center;
}

.plus {
  margin: 0px 30px;
  height: 10vh;
  font-size: 30px;
}

.table {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px;
  border: none !important;
}

.table ul {
  border: none;
  margin: 0px !important;
  padding: 0px !important;
}

.table > :not(:first-child) {
  border: none !important;
}

.table li {
  display: flex;
  justify-content: space-between;
  margin: 0px !important;
  padding: 5px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.table li:last-child {
  border-bottom: none;
}

.x,
.y {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 13px;
  border-radius: 50%;
  justify-content: center;
  color: #6576ff;
  width: 35px;
  height: 35px;
}

.x {
  background-color: #6577ff3f;
  transition: 0.5s ease-in-out;
}

.x:hover {
  background-color: rgba(91, 91, 230, 0.3);
}

.y:hover {
  background-color: #f5f6fa;
}

.invest-container .my-plan span {
  color: #fff;
  background-color: var(--secondary);
  padding: 3px;
  border-radius: 3px;
  font-size: 13px;
}

.t-container {
  background-color: white;
  padding: 30px 20px 20px;
  border-radius: 5px;
}

table {
  background-color: white;
  color: rgba(0, 0, 0, 0.6);
  padding: 30px;
  width: 100%;
}

tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

th {
  background-color: #f5f6fa;
  padding: 6px;
}

td {
  padding: 5px;
}

.adminBtn {
  color: #fff;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 13px;
  border: none;
  outline: none;
  border-radius: 4px;
  margin-left: 20px;
  padding: 15px 30px;
  letter-spacing: 0.02em;
  transition: all .5s;
}

.adminCancelBtn {
  background-color: #e85347;
}

.adminNotMatureBtn, .adminMatureBtn {
  background-color: #09c2de;
}

.adminBtn:hover {
  transform: scale(0.97);
}

@media screen and (max-width: 1200px) {
  .table {
    display: block;
  }
  .invest-container {
    padding: 15vh 20px 50px;
  }
}

@media screen and (max-width: 560px) {
  .balance-s {
    display: block;
  }

  .balance-c {
    padding: 20px;
    margin-top: 20px;
  }

  .invest-container .right {
    margin-top: 20px;
  }
  .my-plan {
    display: block;
  }
  .balance {
    display: flex;
    align-items: flex-start;
  }
  .plus {
    margin: 0px;
    height: auto;
  }
  .adminBtns {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .adminBtn {
    padding: 10px 20px;
    margin-left: 0px;
    font-size: 10px;
  }
}
