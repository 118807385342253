body {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.65;
  color: rgb(82, 100, 132);
  text-align: left;
  background-color: #f5f6fa;

  margin: 0;
}

.planSelect {
  color: rgb(82, 100, 132);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

/* REWRITING THE CSS FOR THE INVESTMENT SECTION */

.investcontainer {
  padding: 100px 60px 50px;
  margin-bottom: 0;
  width: 100%;
  min-height: 100vh;
}
.investcontainer h1 {
  margin-bottom: 50px;
  font-size: 2.5rem;
  letter-spacing: -0.03em;
  font-weight: 400 !important;
}

.investcontainer p {
  font-size: 0.875rem;
  font-weight: 500;
  color: rgb(52, 67, 87);
}

.investamount {
  display: grid;
  grid-template-columns: 16.7% 16.7% 16.7% 16.7% 16.7% 16.7%;
  width: 94%;
  column-gap: 6px;
}
.investcontainer p,
.investcontainer label {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #8094ae;
  font-weight: 300;
}
.investamount p {
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #dbdfea;
  background: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 1.25rem;
  padding: 1rem;
  width: 100%;
  margin-bottom: 0;
  margin-right: 10px;
  transition: all 0.3s;
}

.investamount .withdrawactivep {
  border: 1px solid #6576ff;
  background: #6576ff;
  color: #fff;
}
.formwithdraw form {
  margin-left: 0;
  padding-left: 0;
  width: 100%;
}
.inputField {
  font-size: 1.25rem;
  color: #8094ae;
  border-color: #dbdfea;
}

.packagedropdown {
  width: 100%;
  background-color: #fff;
  border: 1px solid #dbdfea;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  position: relative;
}

.popoverInvestment {
  width: 100%;
  background-color: #fff;
  border: 1px solid #dbdfea;
  border-radius: 4px;
}

.popoverInvestment > div {
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
}

/* REWRITING THE CSS FOR THE INVESTMENT SECTION */

.investcontainer span:first-child {
  display: block;
}
.dropdownplan {
  padding: 15px 140px 20px 20px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid rgba(128, 128, 128, 0.404);
  border-radius: 5px;
}

.investment {
  display: flex;
  justify-content: space-between;
}

.investmentinput {
  width: 100%;
}
.investmentdetails {
  border: 1px solid rgba(128, 128, 128, 0.404);
  width: 30%;
  height: 100%;
  /* margin-left: 50px; */
  border-radius: 8px;
  background-color: white;
}
.investcontainer .investnote {
  font-style: italic;
  font-size: 13px;
}
.investmentdetails h6 {
  margin: 15px 25px 15px;
  font-family: Nunito, sans-serif;
  font-weight: 700;
  line-height: 1.1;
  color: #364a63;
}
.investmentdetails .investment_btn {
  background-color: rgb(91, 91, 230);
  color: #fff;
  border-radius: 5px;
  padding: 5px 20px;
  font-size: 13px;
  display: block;
  margin: 20px auto;
  transition: 0.5s ease-in-out;
  border: none;
  outline: none;
}
.button {
  background-color: #f5f6fa;
}
.investmenttable {
  border-bottom: 1px solid rgb(229, 233, 242);
}
.investmenttable ul {
  display: grid;
  grid-template-columns: 50% 50%;
}
.investmenttable ul li {
  /* display: block; */
  /* width: 100%;
  height: 100%;
  margin-right: 30px; */
  margin-bottom: 8px;
  font-size: 13px;
}
.subtext {
  display: block;
  font-size: 13px;
  color: rgb(128, 148, 174);
  font-family: "Nunito", sans-serif, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif;
  margin-right: 0;
  padding-right: 0;
}
.text {
  font-family: "Nunito", sans-serif, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif;
  font-size: 13px;
  color: rgb(54, 74, 99);
  display: block;
  margin-right: 0;
  padding-right: 0;
}
.leadtext {
  font-size: 0.875rem;
  font-weight: 700;
  color: rgb(54, 74, 99);
  display: block;
  margin-right: 0;
  padding-right: 0;
}
.leadtext span {
  margin-left: 0;
  margin-right: 20px;
  padding: 0;
  font-size: 0.875rem;
  font-weight: 700;
  color: rgb(54, 74, 99);
  font-family: Roboto, sans-serif, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif;
}
.amounttoinvest {
  border-bottom: 1px solid rgb(229, 233, 242);
  padding-top: 10px;
}
.total {
  border-bottom: 1px solid rgb(229, 233, 242);
  padding-top: 20px;
  padding-bottom: 6px;
}
.subtexttotal {
  font-size: 0.875rem;
  font-weight: 700;
  color: rgb(54, 74, 99);
  display: block;
  font-family: Roboto, sans-serif, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif;
}


@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 900px) {
  .investment {
    flex-direction: column;
  }
  .investcontainer {
    min-height: 100vh;
    padding: 80px 30px 25px 30px;
  }
  .investcontainer h1 {
    width: 100%;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  .investcontainer p {
    line-height: 1.5rem;
    color: #8094ae;
    line-height: 1.5rem;
    font-weight: 400;
    width: 90%;
    padding: 6px;
  }
  .investamount {
    grid-template-columns: 33.3% 33.3% 33.3%;
    width: 100%;
    column-gap: 3px;
    row-gap: 10px;
  }
  .investcontainer .investnote {
    font-size: 10px;
  }
  .investmenttable ul li {
    display: block;
  }

  .row-col {
    display: flex;
    flex-direction: column;
  }

  .investmenttable ul {
    display: block;
  }
  .investmentdetails {
    width: 100%;
  }
  /* .investmenttable {
    display: block;
  } */
}

