.car-image {
  margin-top: 15vh;
}

.typed-text {
  color: #fff;
  font-size: 3rem;
  text-align: center;
  display: flex;
  padding-top: 50vh;
  justify-content: center;
}

.banner-p {
  color: #fff;
  font-size: 1.5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 10px 100px;
}

.h-links {
  text-align: center;
  margin-top: 20px;
}

.h-links a {
  color: #fff;
  border: 1px solid #fff;
  margin: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  transition: 00.5s ease-in-out;
}

.h-links a:hover {
  background-color: var(--secondary);
  border: 1px solid var(--secondary);
  color: #fff;
}

.headerSlides {
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-attachment: fixed;
  background-position: center right;
  background-repeat: no-repeat;
  animation-name: fade;
  animation-duration: 1s;
}

.headerSlides2 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../assets/cryptoImg2.jpeg");
}

.headerSlides3 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../assets/cryptoImg3.jpg");
}

.headerSlides1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../assets/cryptoImg.jpeg");
}

.header {
  position: relative;
}

.header-cards {
  position: absolute;
  bottom: -70px;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  width: 1000px;
}

.header-cards .cards {
  background-color: #fff;
  display: flex;
  padding: 30px 30px 10px 10px;
  border-radius: 10px;
}

.cards:nth-child(2) {
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: rgb(231, 228, 228);
}

.header-cards .cards img {
  width: 60px;
  height: 50px;
}

.header-cards .cards div {
  margin-left: 20px;
}

.header-cards .cards div p {
  text-align: justify;
}

.header-cards .cards div h1 {
  color: var(--secondary);
}

.carousel-item img {
  margin-left: 0px;
}

.header .carousel-control-next,
.header .carousel-control-prev {
  background-color: rgba(0, 0, 0, 0.2) !important;
  height: 75px !important;
  width: 75px !important;
  font-weight: bold !important;
  padding: 30px !important;
  border-radius: 50% !important;
  margin-top: 37% !important;
}

.header .carousel-control-next {
  margin-right: 10px !important;
}

.carousel-control-prev {
  margin-left: 10px !important;
}

.carousel-indicators {
  display: none !important;
}
.carousel-caption {
  text-align: left !important;
  width: 500px;
  height: 300px;
  position: absolute;
  top: 40% !important;
  left: 10% !important;
}

.carousel-caption .top {
  height: 250px;
}

.carousel-caption h1 {
  font-size: 50px;
  font-weight: bold;
}

.carousel-caption a {
  color: #fff;
  border: 1px solid var(--tertiary);
  padding: 10px 25px;
  margin-right: 25px;
  border-radius: 50px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.carousel-caption a:first-child {
  background-color: rgb(29, 28, 28);
}

.carousel-caption a:hover {
  color: #fff;
  background-color: var(--secondary);
  transition: all 0.5s ease-in-out;
}

@media screen and (max-width: 600px) {
  .car-image {
    margin-top: 12vh;
  }
}

@media screen and (max-width: 1200px) {
  .header-cards {
    display: block;
    width: 98%;
    margin-left: 0px;
    top: 510px;
    background-color: transparent;
    box-shadow: none;
  }

  .header-cards .cards {
    background-color: white;
    width: 100%;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }

  .header-cards .cards:first-child {
    border-bottom: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .header-cards .cards:nth-child(2) {
    border-radius: 0px;
    border-left: none;
    border-right: none;
  }

  .header-cards .cards:last-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}

@media screen and (max-width: 800px) {
  .carousel-caption div {
    margin-left: 55%;
    transform: translateX(-50%);
    width: 100%;
  }

  .header .carousel-control-next,
  .header .carousel-control-prev {
    height: 30px !important;
    width: 30px !important;
    font-weight: bold !important;
    padding: 10px !important;
    border-radius: 50% !important;
    margin-top: 45% !important;
  }

  .carousel-caption .top {
    height: 180px;
  }

  .carousel-caption h1 {
    font-size: 30px;
    font-weight: bold;
  }

  .carousel-caption p {
    font-size: 18px;
  }

  .carousel-caption a {
    color: #fff;
    border: 1px solid var(--tertiary);
    padding: 10px 25px;
    margin-right: 25px;
    border-radius: 50px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }

  .typed-text {
    font-size: 2.5rem;
  }

  .h-links a {
    margin: 8px;
    padding: 8px 18px;
  }
}

@media screen and (max-width: 700px) {
  .carousel-caption div {
    margin-left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }
}

@media screen and (max-width: 520px) {
  .header img {
    height: 500px;
  }
  .carousel-caption div {
    margin-left: 55%;
    transform: translateX(-50%);
    width: 100%;
  }

  .carousel-caption p {
    width: 70%;
  }

  .carousel-caption a {
    padding: 10px 15px;
    font-size: 16px;
  }

  .header .carousel-control-next,
  .header .carousel-control-prev {
    margin-top: 65% !important;
  }
}

@media screen and (max-width: 450px) {
  .header img {
    height: 500px;
  }
  .carousel-caption div {
    margin-left: 55%;
    transform: translateX(-50%);
    height: 100px;
    width: 100%;
  }

  .carousel-caption p {
    width: 60%;
  }

  .carousel-caption a {
    padding: 5px 15px;
    font-size: 16px;
    top: 50px;
  }

  .header .carousel-control-next,
  .header .carousel-control-prev {
    margin-top: 65% !important;
  }

  .typed-text {
    font-size: 2rem;
  }

  .h-links a {
    margin: 5px;
    padding: 5px 15px;
  }

  .banner-p {
    padding: 10px 20px;
    font-size: 1em;
  }
}
