.divform {
  margin-top: 46px;
  background-color: #fff;
}
.form {
  min-height: 100vh;
  position: relative;
  width: 40%;
  margin: auto;
  padding: 10px;
  padding-top: 20px;
  font-family: "Nunito", sans-serif;
}
.nk-block-title {
  font-family: 700;
}
.logoContainer {
  /* text-align: center;/ */
  cursor: pointer;
  margin: 25px;
}
.textfield {
  border: 0.5px solid #8091a758;
  border-radius: 8px;
  padding: 30px;
}
.label {
  font-weight: 500;
  cursor: pointer;
}
.inputField {
  padding: 10px;
}
.inputField::placeholder {
  color: #c0cee7;
}
.checkboxbotton form-check-label {
  cursor: pointer;
}
.checkboxbotton {
  display: flex;
  align-items: baseline;
}
.checkboxbotton span {
  color: #798bff;
  margin-left: 4px;
}
.accountsignin {
  text-align: center;
}
.accountsignin span {
  color: #798bff;
}
.checkboxbotton label {
  cursor: pointer;
}
a {
  text-decoration: none;
}
/* @media only screen and (max-width: 900px) {
  .textfield {
    width: 100%;
    margin: 0px;
  }
} */
@media only screen and (max-width: 950px) {
  .form {
    min-height: 100vh;
    position: relative;
    width: 60%;
    margin: auto;
    padding: 10px;
    font-family: "Nunito";
  }
}

@media only screen and (max-width: 768px) {
  .form {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .form {
    width: 100%;
    padding: 20px;
  }
  .textfield {
    padding: 10px;
  }
  .logoContainer {
    text-align: center;
  }
  .inputField {
    padding: 5px;
  }
  .inputField::placeholder {
    /* height: calc(2.625rem + 2px); */
    /* padding: 0px 10px; */
    font-size: 0.9375rem;
    line-height: 1.25rem;
  }
}

@media only screen and (max-width: 320px) {
  .form {
    width: 100%;
    padding: 10px;
  }
}
