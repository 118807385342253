body {
  background-color: #f5f6fa;
}
.withdrawcontainer {
   padding: 15vh 120px 50px;
  margin-bottom: 0;
  width: 100%;
}
.withdrawcontainer h1 {
  margin-bottom: 50px;
  font-size: 2.5rem;
  letter-spacing: -0.03em;
  font-weight: 400 !important;
}

.withdrawcontainer p {
  font-size: 0.875rem;
  font-weight: 500;
  color: rgb(52, 67, 87);
}

.amount {
  display: grid;
  grid-template-columns: 16.7% 16.7% 16.7% 16.7% 16.7% 16.7%;
  width: 60%;
}
.amount p {
  /* background-color: blue;
  margin-right: auto;
  padding: 1rem ; */
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #dbdfea;
  background: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 1.25rem;
  padding: 1rem;
  width: 90%;
  margin-bottom: 0;
  margin-right: 10px;
  transition: all 0.3s;
}

.amount .withdrawactivep {
  border: 1px solid #6576ff;
  background: #6576ff;
  color: #fff;
}
.formwithdraw form {
  margin-left: 0;
  padding-left: 0;
  width: 60%;
}
.inputField {
  /* padding: 20px; */
  /* padding: 1rem 4rem 1.25rem 1.5rem; */
  /* height: 3.75rem; */
  font-size: 1.25rem;
  color: #8094ae;
  border-color: #dbdfea;
}

@media only screen and (max-width: 768px) {
  /* .form {
      width: 80%;
    } */
}

@media only screen and (max-width: 600px) {
  .withdrawcontainer h1 {
    margin-bottom: 20px;
  }
  .withdrawcontainer {
    padding: 80px 30px 25px 30px;
  }
  .withdrawcontainer p {
    line-height: 1.5rem;
    color: #8094ae;
    width: 90%;
    padding: 6px;
  }
  .amount {
    grid-template-columns: 33.3% 33.3% 33.3%;
    width: 100%;
    column-gap: 3px;
    row-gap: 10px;
  }
}
